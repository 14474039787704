import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function EngSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    The speaking part includes <span className=' text-red-600'>{read.user.noq.speaking}</span> questions and you have 90 seconds to answer each of them.
                </p>
                <br />
                <p>
                    You will see the timer for the related questions on the top right of the application and your camera being recorded on the top left. The speaking part will take <span className=' text-red-600'> A TOTAL OF {read.user.noq.speaking * 1.5} mins to answer all of the questions.</span> Please take your time. Bring a piece of paper or your on your phone and start taking notes to keep track of your answers. Please do not read directly from your notes, just use them to explain the ideas you jotted down.
                </p>
                <br />
                <p>
                        You must speak at least <span className=' text-red-600'>45</span> seconds to be within the acceptable range of our assessment.
                </p>
                <br />
                <p>
                <span className=' text-red-600'> YOU CANNOT USE A WRITTEN TEXT, A MEMORIZED SPEECH, CHATGPT or any other AI </span>. We need to hear your voice and your voice alone without the help of anyone around you.
                </p>
                <br />
                <p>
                   When you are ready you should press the 🔴 record button and when you are done, you should 🟥 stop the recording. If you do not press the stop button, the application will automatically end the recording after certain time.
                </p>
                <br />
                <p>
                    Your monitor, body movement and eyes are being recorded. You will receieve a 0 if cheat in any sort or form.
                </p>
                <br />
                <h1 className=' text-2xl'>
                    If you understand the instruction, you can press I UNDERSTAND and start the speaking part. (This dialogue will disappear after some time)
                </h1>

            </div>

        </>
    )



}


export default EngSpeaking