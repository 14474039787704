import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Hosgeldiniz() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">


                <h1 className='text-3xl'>
                    Hoş geldin,
                </h1>
                <p>
                    Bu sınav {read.totalQuestions} sorularını içerir ve soruları okuma ve dinleme sorularını ve soruları yazma ve yazma zamanını yanıtlamak için 60 saniyeniz vardır.
                </p>
                <br />
                <p>
                    Uygulamanın sağ üst kısmındaki ilgili soruların zamanlayıcıyı ve web kameranızın sol üstte kaydedildiğini göreceksiniz.Boşta kalırsanız sınav otomatik olarak 60 dakika sonra biter.Eğer öyleyse, aynı sınavı tekrar alma şansınızı kaybedeceksiniz.
                </p>
                <br />
                <p>
                    Hizmet Şartlarımızı okumanız ve kabul etmeniz gerekir ve sınavı almak için aktif web kamerası ve mikrofonunuz olmalıdır.
                </p>
                <br />
                <p>
                    Web kameranız çalışmıyorsa ve kendinizi göremiyorsanız veya mikrofon bağlanmışsa, başlangıç düğmesi etkinleştirilmez ve sınavı başlatamazsınız.Muayene yapmadan önce çalışan bir web kamerası ve mikrofonunuz olduğundan kesinlikle emin olmalısınız.
                </p>
                <br />
                <p>
                    Sesinizi ve videonuzu kaydettiğimiz için İnternet bağlantınızın iyi olduğundan emin olmalısınız.Ayrılıyorsanız, sınavı bıraktığınız yerden devam etmek için aynı bağlantıyı kullanabilirsiniz.
                </p>
                <br />
                <p>
                    Çoktan seçmeli soruların hiçbiri için olumsuz bir anlam yoktur, bu nedenle tüm soruları olabildiğince cevaplamaktan çekinmeyin.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Talimatı anlarsanız, anladığım ve sınavı başlatabileceğiniz.İyi şanlar!
                </h1>

            </div>

        </>
    )



}


export default Hosgeldiniz