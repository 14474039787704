import React from 'react';
import { WsContext } from '../App'
import { pack } from 'msgpackr';
import { write, useSnap } from '../store'


function WsListener() {

    // const ws = React.useContext(WsContext);


    const read = useSnap()
    const ws = read.ws
    React.useEffect(() => {
        ws.onmessage = async ({ data: message }) => {
            message = JSON.parse(message)
            // console.log('🤡🤡received:', message);
            switch (message.type) {
                // case 'ID': // getting the id means that we have ws running . This is the starting point
                //     write.id = message.id
                //     console.log('ID is in', message.id)
                //     break;
                case 'SAVED': // getting the id means that we have ws running . This is the starting point
                    console.log('🟥🟥🟥🟥', message.next)
                    setTimeout(() => {
                        write.saved = true
                        write.currentQuestion = message.next
                        // write.stopTimer = false // in the past it would start the timer from here. disabled it in favor of the speaking.
                        // message.next >= 999 && (write.page = 'DONE')
                    }, 1111);
                    break;
                case 'AUTH': // getting the id means that we have ws running . This is the starting point
                    if (message.valid) {
                        write.auth = true
                        write.questions = message.questions
                        write.totalQuestions = message.questions.length
                        write.currentQuestion = message.currentQuestion
                        write.user = message.user

                        if (message.questions.length < message.currentQuestion) { write.page = 'DONEREVISITED' } else { write.page = 'TEST' } // done or not?


                    } else {
                        write.auth = false
                    }
                    break;
                case 'DUPLICATE': // getting the id means that we have ws running . This is the starting point
                    write.user.language = message.language
                    write.page = 'DUPLICATE'
                    break;

                default:
                    break
            }

        }

        ws.send(pack({ type: 'ID', invID: read.id }))
    }, []);

}

export default WsListener;
