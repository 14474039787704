import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function AraReading() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">

                <p>
                    يتضمن جزء القراءة <span className='Text-Red-600'> {read.user.noq.reading} </span> أسئلة ولديك 60 ثانية للإجابة على كل منها.لن تتكرر الأسئلة ، وإذا نفدت الوقت ، فسوف ينتقل التطبيق تلقائيًا إلى السؤال التالي.
                </p>
                <br />
                <p>
                    سترى الموقت للأسئلة ذات الصلة في أعلى يمين التطبيق ويتم تسجيل الكاميرا في أعلى اليسار.سيستغرق جزء القراءة <span className='Text-Red-600'> إجمالي {read.user.noq.reading} للإجابة على جميع الأسئلة. </span> يرجى أخذ وقتك.
                </p>
                <br />
                <p>
                    لا توجد نقطة سلبية لأي من أسئلة الاختيار من متعدد ، لذلك لا تتردد في الإجابة على جميع الأسئلة قدر الإمكان.
                </p>
                <br />
                <p>
                    يتم تسجيل شاشتك وحركتك وعينك.سوف تتلقى 0 إذا فتحت طلبًا من طرف ثالث أو غش في أي نوع من النماذج.
                </p>
                <br />
                <h1 className='text-2xl'>
                    إذا فهمت التعليمات ، فيمكنك الضغط على جزء من القراءة.
                </h1>

            </div>

        </>
    )



}


export default AraReading