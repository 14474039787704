import React from 'react';
import Lottie from "lottie-react";
import b from "../../assets/browser.json"
// import { useSnap } from '../../store'
// import toast from 'react-hot-toast';


function EngWarning() {
    // const read = useSnap()


    return (

        <>
            <div className="flex flex-col max-w-fit justify-center align-middle text-center">
            <Lottie size={55} className="w-52 self-center" animationData={b} loop={true} />
            <p className=' text-red-600 text-xl'> YOU ARE BEING WATCHED 👀, PLEASE DO NOT CHANGE OR MINIMIZE THE TABS.  </p>
            </div>


        </>
    )



}


export default EngWarning