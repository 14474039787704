import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function TurSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Konuşma bölümünde <span className='text-red-600'> {read.user.noq.speaking} </span> soruları içerir ve her birini cevaplamak için 3 dakikanız vardır.
                </p>
                <br />
                <p>
                    Uygulamanın sağ üst kısmındaki ilgili soruların zamanlayıcıyı ve kameranızın sol üstte kaydedildiğini göreceksiniz.Konuşma kısmı <span className='text-red-600'> Tüm soruları cevaplamak için toplam {read.user.noq.speaking * 3} dakika alacaktır. </span> Lütfen zaman ayırın.
                </p>
                <br />
                <p>
                    Değerlendirmemizin kabul edilebilir aralığında olmak için en az <span className='text-red-600'> 1 </span> dakikayı konuşmalısınız.
                </p>
                <br />
                <p>
                    <span className='text-red-600'> Yazılı bir metinden veya ezberlenmiş bir konuşmadan okuyamazsınız </span>.Etrafınızdaki kimsenin yardımı olmadan sesinizi ve sesinizi yalnız duymalıyız.
                </p>
                <br />
                <p>
                    Hazır olduğunuzda 🔴 Kayıt düğmesine basmalısınız ve işiniz bittiğinde, 🟥 kaydı durdurmalısınız.Durdurma düğmesine basmazsanız, uygulama belirli süreden sonra kaydı otomatik olarak bitirir.
                </p>
                <br />
                <p>
                    Monitörünüz, hareketiniz ve gözleriniz kaydediliyor.Üçüncü taraf bir başvuruyu açarsanız veya herhangi bir şekilde hile yaparsanız 0 alırsınız.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Talimatı anlarsanız, anladığım ve konuşma bölümünü başlatabilirim.
                </h1>

            </div>

        </>
    )



}


export default TurSpeaking