
import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { write, useSnap } from '../store'
import Turkishtos1 from './locale/Turkishtos1';
import Turkishtos2 from './locale/Turkishtos2';
import EngWarning from './locale/engWarning';
import { locale } from './locale';


function NewModal({ type, title = 'Terms of service' }) {
    const read = useSnap()
    const timerRef = React.useRef()

    React.useEffect(() => {
        if (type !== 'tos1' && type !== 'tos2' && type !== 'welcome') {
            timerRef.current = setTimeout(() => {
                console.log('⏳⏳ OUT')
                handleClick()
            }, 180000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    // const [content, setContent] = React.useState();

    // console.log('type', type)

    const RenderIt = () => {
        switch (type) {
            case 'tabCTRL':
                return <EngWarning />
            case 'tos1':
                return <Turkishtos1 />
            case 'tos2':
                return <Turkishtos2 />
            case 'welcome':
                return locale[read.user.language].instruction.welcome
            case 'reading':
                return locale[read.user.language].instruction.reading
            case 'listening':
                return locale[read.user.language].instruction.listening
            case 'speaking':
                return locale[read.user.language].instruction.speaking
            case 'writing':
                return locale[read.user.language].instruction.writing
            default:
                break;
        }
    }
    function handleClick(t) {
        //! MUST BE ENABLED THE FIRST 2 LINES
        console.log('🟢🟢', 'clicked')
        clearTimeout(timerRef.current)
        write.instruction[type] = true
        localStorage.setItem(type, true);

        // console.log(localStorage.getItem(type), '🟢🟢')

    }



    return (
        <>
            <div className={`absolute z-50 ${read.instruction[type] ? 'hidden' : ''} overflow-x-hidden overflow-y-auto top-12 md:top-auto`}>
                <div className="relative w-full h-full max-w-2xl md:h-auto">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {title}
                            </h3>
                            <CountdownCircleTimer
                                isPlaying
                                isGrowing
                                size={30}
                                duration={240}
                                strokeWidth={3}
                                colors={['#008000', '#F7B801', '#A30000']}
                                colorsTime={[120, 60, 10]}
                            />
                            
                        </div>
      
                        {/* Modal body */}
                        <div className="p-6 space-y-6 overflow-y-auto h-56 md:h-96">



                            <RenderIt />


                        </div>
                        {/* Modal footer */}
                        <div className="flex items-center justify-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={handleClick} data-modal-hide="staticModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{locale[read.user.language].button.understand}</button>

                        </div>
                    </div>
                </div>
            </div>



        </>
    )


}




export default NewModal