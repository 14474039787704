import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function ItaListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                La parte di ascolto include <span className = 'text-red-600'> {read.user.noq.listening} </span> e hai 60 secondi per rispondere a ciascuno di essi.Le domande non si ripeteranno e se si esaurisce il tempo, l'applicazione passerà automaticamente alla domanda successiva.
                </p>
                <br />
                <p>
                    Vedrai il timer per le domande correlate in alto a destra dell'applicazione e la fotocamera registrata in alto a sinistra.La parte di ascolto prenderà <span className = 'text-red-600'> Un totale di {read.user.noq.listening} minuti per rispondere a tutte le domande. </span> Per favore prenditi il tuo tempo.
                </p>
                <br />
                <p>
                        Vedrai un pulsante di riproduzione.Premere il pulsante di riproduzione quando sei pronto per l'ascolto.
                </p>
                <br />
                <p>
                        Il timer inizia quando hai finito di ascoltare l'audio.L'audio verrà riprodotto solo una volta e non potrai ascoltarlo.Da lì in poi hai tempo per scegliere una delle risposte.
                </p>
                <br />
                <p>
                    Non esiste un punto negativo per nessuna delle domande a scelta multipla, quindi sentiti libero di rispondere a tutte le domande il più possibile.
                </p>
                <br />
                <p>
                    Il monitor, il movimento e gli occhi vengono registrati.Riceverai uno 0 se apri un'applicazione di terze parti o imbrogliate in qualsiasi tipo di forma.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Se capisci le istruzioni, puoi premere, capisco e avvia la parte di ascolto.
                </h1>

            </div>

        </>
    )



}


export default ItaListening