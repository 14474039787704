import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function GerSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Der sprechende Teil enthält <span className='text-red-600'> {read.user.noq.speaking} </span> Fragen, und Sie haben 3 Minuten Zeit, um jeweils zu beantworten.
                </p>
                <br />
                <p>
                    Sie sehen den Timer für die damit verbundenen Fragen oben rechts in der Anwendung und Ihre Kamera wird oben links aufgezeichnet.Der sprechende Teil wird <span className='text-red-600'> eine Gesamtsumme von {read.user.noq.speaking * 3} Minuten übernehmen, um alle Fragen zu beantworten. </span> Bitte nehmen Sie sich Zeit.
                </p>
                <br />
                <p>
                    Sie müssen mindestens <span className='text-re-red-600'> 1 </span> minute im akzeptablen Bereich unserer Bewertung sein.
                </p>
                <br />
                <p>
                    <span className='text-red-600'> Sie können nicht aus einem geschriebenen Text oder einer auswendig gelernten Sprache lesen </span>.Wir müssen Ihre Stimme und Ihre Stimme allein ohne die Hilfe von jemandem in Ihrer Umgebung hören.
                </p>
                <br />
                <p>
                    Wenn Sie bereit sind, sollten Sie die 🔴 -Datensatzstaste drücken und wenn Sie fertig sind, sollten Sie die Aufnahme stoppen.Wenn Sie nicht die Stopptaste drücken, beendet die Anwendung die Aufzeichnung nach einer bestimmten Zeit automatisch.
                </p>
                <br />
                <p>
                    Ihr Monitor, Ihre Bewegung und Ihre Augen werden aufgezeichnet.Sie erhalten eine 0, wenn Sie einen Drittantrag an Drittanbietern eröffnen oder in irgendeiner Form betrügen.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Wenn Sie die Anweisung verstehen, können Sie den sprechenden Teil verstehen und beginnen.
                </h1>

            </div>

        </>
    )



}


export default GerSpeaking