import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function EngListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    The listening part includes <span className=' text-red-600'>{read.user.noq.listening}</span> questions and you have 60 seconds to answer each of them. The questions will not repeat and if you run out of time, the application will automatically move to the next question.
                </p>
                <br />
                <p>
                    You will see the timer for the related questions on the top right of the application and your camera being recorded on the top left. The listening part will take <span className=' text-red-600'> A TOTAL OF {read.user.noq.listening} minutes to answer all of the questions.</span> Please take your time. 
                </p>
                <br />
                <p>
                        You will see a play button. Press the play button when you are ready to listen.
                </p>
                <br />
                <p>
                        The timer begins when you have finished listening to the audio. The audio will be played only once and you won't be able to hear it again. From there on you have time to choose one of the answers.
                </p>
                <br />
                <p>
                    There is no negative point for any of the multiple choice questions, therefore feel free to answer all the questions as much as you can.
                </p>
                <br />
                <p>
                    Your monitor, movement and eyes are being recorded. You will receieve a 0 if you open a third party application or cheat in any sort of form.
                </p>
                <br />
                <h1 className=' text-2xl'>
                    If you understand the instruction, you can press I understand and start the listening part.
                </h1>

            </div>

        </>
    )



}


export default EngListening