import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function KorListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">

                <p>
                    청취 부분에는 <span className='text-red-600'> {read.user.noq.listening} </span> 질문이 포함되며 각각 60 초가 걸립니다.질문은 반복되지 않으며 시간이 부족하면 응용 프로그램이 자동으로 다음 질문으로 이동합니다.
                </p>
                <br />
                <p>
                    응용 프로그램 오른쪽 상단에 관련 질문에 대한 타이머와 왼쪽 상단에 카메라가 기록됩니다.청취 부분은 <span className='text-red-600'>을 취합니다. 총 {read.user.noq.listening} 모든 질문에 답변합니다. </span> 시간을 내주십시오.
                </p>
                <br />
                <p>
                    재생 버튼이 표시됩니다.들을 준비가되면 재생 버튼을 누릅니다.
                </p>
                <br />
                <p>
                    오디오 듣기를 마치면 타이머가 시작됩니다.오디오는 한 번만 재생되며 다시들을 수 없습니다.거기에서 당신은 답 중 하나를 선택할 시간이 있습니다.
                </p>
                <br />
                <p>
                    객관식 질문에 대한 부정적인 점은 없으므로 가능한 한 모든 질문에 자유롭게 대답하십시오.
                </p>
                <br />
                <p>
                    모니터, 움직임 및 눈이 기록되고 있습니다.타사 신청서를 열거나 어떤 종류의 형태로 치트를 열면 0을 받게됩니다.
                </p>
                <br />
                <h1 className='text-2xl'>
                    당신이 지시를 이해하면, 당신은 내가 이해하고 듣기 부분을 시작할 수 있습니다.
                </h1>

            </div>

        </>
    )



}


export default KorListening