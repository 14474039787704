import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Bienvenida() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">

            <h1 className = 'text-3xl'>
                     مرحباً،
                 </h1>
                 <p>
                     يتضمن هذا الاختبار أسئلة {read.totalQuestions} ولديك 60 ثانية للإجابة على أسئلة القراءة والاستماع ووقت متنوع لأسئلة التحدث والكتابة.
                 </p>
                 <br />
                 <p>
                     سترى مؤقت الأسئلة ذات الصلة في الجزء العلوي الأيمن من التطبيق ويتم تسجيل كاميرا الويب الخاصة بك في الجزء العلوي الأيسر.
                 </p>
                 <br />
                 <p>
                     تحتاج إلى قراءة شروط الخدمة الخاصة بنا والموافقة عليها ويجب أن يكون لديك كاميرا ويب وميكروفون نشطان من أجل إجراء الاختبار.
                 </p>
                 <br />
                 <p>
                     إذا كانت كاميرا الويب الخاصة بك لا تعمل ولا يمكنك رؤية نفسك أو الميكروفون متصل الآن ، فلن يتم تنشيط زر البدء ولن تتمكن من بدء الاختبار. يجب أن تتأكد تمامًا من أن لديك كاميرا ويب وميكروفون عاملين قبل إجراء الاختبار.
                 </p>
                 <br />
                 <p>
                     يجب عليك التأكد من أن اتصالك بالإنترنت على ما يرام لأننا نسجل صوتك وفيديوك. إذا تم قطع اتصالك ، فيمكنك استخدام نفس الرابط لمتابعة الاختبار من حيث توقفت.
                 </p>
                 <br />
                 <p>
                     لا توجد نقطة سلبية لأي من أسئلة الاختيار من متعدد ، لذلك لا تتردد في الإجابة على جميع الأسئلة بقدر ما تستطيع.
                 </p>
                 <br />
                 <h1 className = 'text-2xl'>
                    إذا فهمت التعليمات ، يمكنك الضغط على "أفهم" وبدء الاختبار. حظ سعيد!
                 </h1>

             </div>
        </>
    )



}


export default Bienvenida