import React from 'react';
// import { useSnap } from '../../store'
// import toast from 'react-hot-toast';


function Turkishtos1() {
    // const read = useSnap()


    return (


        <div className="flex flex-col w-60 md:w-full ">


            <div className=''>
                <p className="MsoNormal" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Kişisel Verilerin
                    Korunması Kanunu Çerçevesinde</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Global Assessment Eğitim ve Danışmanlık Tic. Ltd. Şti.</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Eğitim Alanlar İçin Verilerinin İşlenmesine İlişkin</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Aydınlatma Metni</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Global Assessment Eğitim ve Danışmanlık Tic. Ltd. Şti. (Global Assessment)<b>&nbsp;</b>Veri
                    Sorumlusu Sıfatıyla;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; kişisel verilerinizin
                    korunmasına ve güvenliğine önem göstermekte, bu sebeple de başta özel hayatın
                    gizliliği olmak üzere kişisel verilerin korunması amacıyla düzenlenen 6698
                    sayılı Kişisel Verilerin Korunması Kanunu ve ilgili diğer mevzuat hükümleri
                    uyarınca kişisel verilerinizin toplanma şekilleri, işlenme amaçları, işlemenin
                    hukuki nedenleri, işbu verilerin kimlere hangi amaçla aktarıldığı ve bu
                    konudaki haklarınıza ilişkin olarak, sizleri KVKK kapsamındaki aydınlatma
                    yükümlülüğümüz çerçevesinde bilgilendirmek isteriz. (<b><i>Kişisel Veri</i></b><i>,
                        KVKK Kapsamında kişisel veri kimliği belirli veya belirlenebilir gerçek kişiye
                        ilişkin her türlü bilgiyi ifade etmektedir.)</i></span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '14.2pt', textAlign: 'justify', textIndent: '-14.2pt', lineHeight: '11.65pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>1.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    Verilerin Toplanma Yöntemi ve Hukuki Sebebi</span></u></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Şirketimiz,
                    eğitim alanların verilerini kanun gereğince aşağıda sayılan kanallar
                    vasıtasıyla, tamamen veya kısmen otomatik ya da veri kayıt sisteminin parçası
                    olmak kaydıyla otomatik olmayan yollarla, sözlü, yazılı veya elektronik şekilde
                    kanuna uygun olarak toplamakta ve Kanunun 5’ inci ve 6’ ıncı maddelerinde
                    belirtilen kişisel veri işleme şartları ve amaçları dâhilinde bu metnin c ve d
                    bendinde belirtilen amaçlarla işleyebilmekte ve aktarabilmektedir.</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    Veriler:</span></u></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>İletişim
                    Bilgisi: Telefon numarası, e-posta adresi</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Görsel ve
                    İşitsel Bilgi: Fotoğraf, kamera kayıtları, okul bünyesinde gerçekleştirilen
                    etkinliklerin / projelerin bilgi ve fotoğraf kayıtları</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}><span style={{ textDecoration: 'none' }}>&nbsp;</span></span></u></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Özel Nitelikli
                    Kişisel Veriler:</span></u></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Sağlık Bilgisi:
                    Hastalık ve alerji bilgileri, gelişim bilgileri, sağlık bilgisi</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Biyometrik ve
                    Genetik Bilgi: Kan grubu</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Eğitim alanın kişisel verileri,</span></u></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Yazılı veya
                    elektronik ortamda yayınlanan başvuru formları ile,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Eğitim alanın
                    mülakat puanları ile</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;Rehber
                    ve/veya Danışman Öğretmen ile yapılacak görüşmelerin tutanakları ile</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;Posta
                    adresleri üzerinden yürütülen yazışmalar, gönderilen kısa mesajlar ve
                    multimedya mesajları, bilgi formları da dâhil ve sair iletişim yöntemleri ile
                    her türlü iletişim kanalları aracılığı toplanmaktadır.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Global
                    İngilizce Eğitim ve Danışmanlık Tic. Ltd. Şti. eğitim alanlara yönelik
                    operasyonel süreçlerini yürütürken işlediği kişisel verilerde Global Assessment
                    kişisel verilerin işlenmesi ve korunması politikasına ve Milli Eğitim Bakanlığı
                    okul öncesi eğitim ve ilkokul kurumları yönetmeliği, Milli Eğitim Bakanlığı
                    Özel Öğretim Kurumları Yönetmeliği, Milli Eğitim Bakanlığı Özel Eğitim ve
                    Rehberlik Hizmetleri Yönetmeliği, 5510 sayılı sosyal sigortalar ve genel sağlık
                    sigortası kanununa uygun hareket eder.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kanunların
                    emredici hükümleri gereği gerçek kişilerin bilgilerinin doğru ve güncel
                    tutulması esastır. Bu nedenle, belirli zaman aralıkları ile eğitim alanların
                    kişisel bilgilerini güncellenmesi talep edilebilir. Ayrıca eğitim alanların da
                    bilgilerinde herhangi bir değişiklik olduğu takdirde söz konusu bilgileri
                    Şirketimize iletmeleri beklenmektedir.</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: 'normal' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>2.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel Verilerin İşlenme Amaçları</span></u></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: 'normal' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: 'normal' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    verileriniz Global Assessment&nbsp;tarafından;</span></b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}> aşağıdaki amaçlar
                        dahilinde açık rızanıza binaen veya KVKK kapsamında hukuken izin verilen diğer
                        hallerde işlenmektedir.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -Şirketimiz tarafından verilen eğitim ve eğitim alanlar arasındaki ilişkilerin
                    düzenlenmesi, planlanması, memnuniyetin sağlanması, </span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    - Şirketimiz tarafından yapılan yabancı dil mülakat hizmetinin mülakat yapan
                    adayların görsel video, ses ve ekran görüntü kaydının online olarak yapılması
                    ve mülakatı talep eden şirket, yönetici ve ilgili şirket personeli ile
                    paylaşımı,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Mülakat yapılacak adayların kayıtları
                    oluşturulması ve yönetilmesi, </span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Adaylar ile ilgili olarak yasal sınırlar
                    içerisinde değerlendirme çalışmalarının yapılması,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Hizmet şartlarımızda ve elektronik
                    hizmetlerimizde meydana gelebilecek değişiklikler hakkında bilgilendirme
                    yapılması,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Şirketimiz ve iş ilişkisi içerisinde
                    olduğu kişilerin hukuki ve ticari güvenliğinin temini,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Adayların şikayetlerinin çözülmesi ve
                    veri erişim veya düzeltme taleplerinin işleme alınması,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Sosyal medya paylaşım fonksiyonunun
                    kolaylaştırılması,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Bilgi güvenliği süreçlerinin planlanması,
                    bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textIndent: '35.45pt', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Yasal hakları belirlemek ve bunları
                    savunmak; faaliyetlerimizi veya iş ortaklarımızın faaliyetlerini, haklarımızı,
                    gizliliğimizi, güvenliğimizi veya malımızı ve/veya sizin veya başkalarının bu
                    sayılan varlıklarının korunması ve kullanılabilir çarelerin uygulanması veya
                    zararımızın sınırlandırılması gibi</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -Eğitim alanın bünyemizdeki başka bir eğitim programına/paketine dahil olması
                    halinde işbu sürecin yönetilmesi,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    - </span></b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>İş ortakları ve tedarikçilerle olan ilişkilerin yönetimi,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Şirketimizin
                    anlaşmalı olduğu servis hizmetinin verilmesi ile ilgili gerekli kayıtların
                    oluşturulması, ilgili yerlere bilgilerin aktarılması</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    - Şirketimizin kalite, bilgi güvenliği ve gizlilik politikalarının ve
                    standartlarının sağlıklı olarak temini ve denetimi amacıyla. </span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    -Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde kamu kurum ve
                    kuruluşlarının taleplerinin yerine getirilmesi,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    - Kanunda belirtilmiş yasal yükümlülüklerin yerine getirilmesi amacıyla
                    işlenmektedir. </span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Kişisel verileriniz yukarıda ifade edilen amaçlar dışında kanun ve mevzuatlar
                    tarafından öngörülen yükümlülüklerimizin yerine getirilmesi gibi amaçlar
                    dahilinde de işlenebileceğini hatırlatmakta fayda görmekteyiz.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '13.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>3.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</span></u></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Global Assessment, kişisel verilerinizi, hukuken izin verilen
                    hallerde ve yukarıda sayılan amaçların varlığı halinde yurt içinde; kanuni
                    yükümlülüklerini yerine getirmek amacıyla Mili Eğitim Bakanlığı, Sosyal
                    Güvenlik Kurumu gibi otoriteler, Bakanlıklar, yargı mercileri gibi yetkili kamu
                    kurum ve kuruluşları ile tedarikçileri, iş bağlantısında bulunduğu ifa
                    yardımcıları ve alt yüklenicileri(güvenlik, işbirliği yapılan ve/veya hizmet
                    alınan iş ortakları, hukuk, muhasebe) ile sunduğu&nbsp;hizmetin amacı doğrultusunda
                    yurt içine veya yurt dışına aktarılabilecektir. Global Assessment tarafından
                    üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli
                    teknik ve hukuki önlemler alınmaktadır. </span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.5pt', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>4.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;
                </span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel Veri Sahibinin (İlgili Kişinin) KVKK Madde 11 Kapsamındaki
                    Hakları</span></u></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel veri
                    sahibi olarak dilediğiniz zaman KVKK’nın 11. Maddesi uyarınca aşağıda
                    belirtilen haklarınızı veri sorumlusu olan Global Assessment’ den talep
                    edebilirsiniz. Bu kapsamda sahip olduğunuz haklar aşağıdaki şekildedir:</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    verilerin işlenip işlenmediğini öğrenme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel veriler
                    işlenmişse buna ilişkin bilgi talep etme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
                    öğrenme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Yurt içinde
                    veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
                    isteme ve bu çerçevede yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                    kişilere bildirilmesini talep etme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>KVKK’ ya ve
                    ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
                    işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde Kişisel Verilerin
                    Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik
                    Hükümleri uyarınca bu verilerin 30 (otuz) gün içerisinde silinmesini, yok
                    edilmesini veya anonim hale getirilmesini ve bu kapsamda yapılan işlemin</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel verilerin
                    aktarıldığı üçüncü kişilere bildirilmesini isteme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;İşlenen
                    verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
                    kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması hâlinde
                    zararın giderilmesini talep etme hakkına sahiptir.</span></p>
                <p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'justify', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Kişisel
                    verilerinizin korunma ve işlenme esasları ile ilgili kapsamlı bilgi için&nbsp;</span></b><span lang="TR"><span style={{ fontFamily: '"Times New Roman",serif' }}>https://www.Globalassessment.com.tr/</span></span><span lang="TR" style={{ color: 'black' }}>&nbsp;</span><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>adresinde yer alan Kişisel Verilerin
                        Korunması ve İşlenmesi Politikası’ nı inceleyebilir, Kişisel Verilerin
                        Korunması Kanunu kapsamın bilgi talebi oluşması halinde aşağıda belirlenen
                        yollarla kanunda belirlenen asgari unsurları taşımak kaydıyla bize
                        ulaşabilirsiniz. Aynı zamanda sizlere kolaylık sağlamak adına şirketimizce
                        oluşturulmuş başvuru formundan da bu başvuru yöntemlerini kullanırken
                        faydalanabilirsiniz. (Tarafımıza yapacağınız başvurularda kullanmak için
                        oluşturulmuş Başvuru Formumuz&nbsp;</span></b><span lang="TR"><span style={{ fontFamily: '"Times New Roman",serif' }}>https://www.Globalassessment.com.tr/</span></span><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;sitesinde
                            mevcuttur)</span></b></p>
                <table className="MsoNormalTable" border={0} cellSpacing={0} cellPadding={0} align="left" style={{ borderCollapse: 'collapse', marginLeft: '6.75pt', marginRight: '6.75pt' }}>
                    <tbody><tr style={{ height: '26.65pt' }}>
                        <td style={{ border: 'solid windowtext 1.0pt', background: '#E5E5E5', padding: '0in 5.4pt 0in 5.4pt', height: '26.65pt' }}>
                            <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Başvuru Yöntemi</span></b></p>
                        </td>
                        <td style={{ border: 'solid windowtext 1.0pt', borderLeft: 'none', background: '#E5E5E5', padding: '0in 5.4pt 0in 5.4pt', height: '26.65pt' }}>
                            <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Başvurunun
                                Yapılacağı Adres</span></b></p>
                        </td>
                    </tr>
                        <tr style={{ height: '39.7pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Şahsen Başvuru(İlgili
                                    Kişinin ya da yetkilisinin ‘vasi/vekil’ bizzat gelerek yaptığı başvuru )</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><i><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Dünya Ticaret Merkezi Egs
                                    Business Park Blokları No:12/1 B1 Blok Kapı No 71, 34149 Bakırköy</span></i></p>
                            </td>
                        </tr>
                        <tr style={{ height: '28.9pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '28.9pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Noter Vasıtasıyla Başvuru</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '28.9pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><i><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Dünya Ticaret Merkezi Egs
                                    Business Park Blokları No:12/1 B1 Blok Kapı No 71, 34149 Bakırköyveli</span></i></p>
                            </td>
                        </tr>
                        <tr style={{ height: '39.7pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Güvenli Elektronik İmza
                                    İle Başvuru [Güvenli elektronik imza ile imzalanarak Kayıtlı Elektronik Posta
                                    (KEP) yoluyla başvurması]</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>………</span></p>
                            </td>
                        </tr>
                        <tr style={{ height: '37.6pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '37.6pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Mobil İmza ya da E-posta
                                    İle Başvuru [İlgili kişi tarafından veri sorumlusuna daha önce bildirilen ve
                                    veri sorumlusunun sisteminde kayıtlı bulunan güvenli elektronik posta
                                    adresini kullanmak suretiyle]</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '37.6pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>kvkk@Globalassessment.com.tr</span></b></p>
                            </td>
                        </tr>
                    </tbody></table>
                <p className="MsoNormal" style={{ lineHeight: '11.75pt' }}><span lang="TR" style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
                <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
                <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Within the framework of the Personal Data Protection Law</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Global Assessment Education and Consulting Trade. Ltd. Sti.</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Regarding the Processing of Data for Trainees</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: '#818181' }}>Illumination Text</span></b></p>
                <p className="MsoNormal" align="center" style={{ marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '14.2pt', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Global Assessment
                    Education and Consulting Trade. Ltd. Sti. (Global Assessment) As Data Controller;
                    attaches importance to the protection and security of your personal data,
                    therefore, in accordance with the Personal Data Protection Law No. 6698, which
                    is regulated for the protection of personal data, especially the privacy of
                    private life, and the provisions of other relevant legislation, the way your
                    personal data is collected, the purposes of processing, the legal reasons for
                    the processing, to whom this data belongs. We would like to inform you within
                    the framework of our obligation to inform you within the scope of KVKK,
                    regarding the transfer of data for this purpose and your rights in this regard.
                    (Personal Data, within the scope of GDPR, means any information relating to a
                    real person whose personal data can be identified or identifiable.)</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '14.2pt', textAlign: 'justify', textIndent: '-14.2pt', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '14.2pt', textAlign: 'justify', textIndent: '-14.2pt', lineHeight: '11.65pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>1.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Personal Data
                    Collection Method and Legal Reason</span></u></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Our company
                    collects the data of the trainees through the channels listed below in
                    accordance with the law, fully or partially automatically or non-automatically
                    provided that they are part of the data recording system, verbally, in writing
                    or electronically, in accordance with the law and personal data specified in
                    Articles 5 and 6 of the Law. Within the scope of data processing conditions and
                    purposes, it can process and transfer it for the purposes specified in clause c
                    and d of this text.</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Personal Data:</span></u></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Contact
                    Information: Phone number, e-mail address</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Visual and
                    Audio Information: Photographs, camera recordings, information and photographic
                    recordings of activities / projects carried out within the school</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}><span style={{ textDecoration: 'none' }}>&nbsp;</span></span></u></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Candidate personal data,</span></u></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>With the
                    application forms published in written or electronic environment,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- With the
                    interview scores of the trainee</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- with the
                    minutes of the meetings to be held with the Guidance and/or Consultant Teacher</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-
                    Correspondence carried out over postal addresses, sent short messages and
                    multimedia messages, including information forms and other communication
                    methods and all kinds of communication channels are collected.</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Global Assessment
                    Education and Consulting Trade. Ltd. Sti. Global Assessment personal data
                    processing and protection policy in the personal data it processes while
                    carrying out its operational processes for education recipients and the
                    Ministry of National Education pre-school education and primary school
                    institutions regulation, the Ministry of National Education Private Education
                    Institutions Regulation, the Ministry of National Education Special Education
                    and Guidance Services Regulation, social media number 5510. acts in accordance
                    with insurance and general health insurance law.</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>In accordance
                    with the mandatory provisions of the laws, it is essential to keep the
                    information of real persons accurate and up-to-date. For this reason, it may be
                    requested to update the personal information of the trainees at certain time
                    intervals. In addition, if there is any change in the information of the
                    trainees, they are expected to forward the said information to our Company.</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: 'normal' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>2.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Purposes of Processing Personal Data</span></u></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: 'normal' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></b></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: 'normal' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Your
                    personal data by Global Assessment; </span></b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>It is processed for the following
                        purposes, based on your express consent or in other cases legally permitted
                        under the GDPR.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    - Organizing and planning the relations between the training and training areas
                    given by our company, ensuring satisfaction,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - The foreign language interview service provided by
                    our company is to make online video, audio and screenshot recording of the
                    interviewing candidates and share it with the company, manager and relevant
                    company personnel who request the interview,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Creating and managing the records of the candidates to be
                    interviewed,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Evaluation of candidates within legal limits,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Informing about changes that may occur in our terms of service
                    and electronic services,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Ensuring the legal and commercial security of our company and
                    the people with whom it has business relations,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>-Resolving candidates' complaints and processing data access or
                    correction requests,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Facilitating the social media sharing function,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Planning information security processes, creating and managing
                    information technology infrastructure,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>- Identifying and defending legal rights; such as protecting our
                    activities or the activities of our business partners, our rights, privacy,
                    security or property and/or such assets of you or others, and applying
                    available remedies or limiting our damage.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Managing this process if the training field is
                    included in another training program/package within our organization,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Management of relations with business partners and
                    suppliers,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -Creating the necessary records regarding the
                    delivery of the contracted service of our company, transferring the information
                    to the relevant places</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - For the purpose of ensuring and auditing the
                    quality, information security and privacy policies and standards of our
                    company.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Fulfilling the demands of public institutions and
                    organizations as required or mandated by legal regulations,</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - It is processed in order to fulfill the legal
                    obligations specified in the law.. </span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    We would like to remind you that your personal data may be processed for
                    purposes other than the purposes stated above, such as fulfilling our obligations
                    stipulated by laws and regulations.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '13.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>3.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>To Whom
                    Personal Data Can Be Transferred And For What Purpose</span></u></b></p>
                <p className="MsoNormal" style={{ marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '13.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Global Assessment, in cases where legally permitted and in the
                    presence of the above-mentioned purposes, your personal data in the country; In
                    order to fulfill their legal obligations, authorized public institutions and
                    organizations such as the Ministry of National Education, Social Security
                    Institution, Ministries, judicial authorities and their suppliers, performance
                    assistants and subcontractors (security, business partners with whom
                    cooperation and / or service are provided, legal authorities) , accounting) and
                    can be transferred to the country or abroad in line with the purpose of the
                    service it provides. Necessary technical and legal measures are taken by Global
                    English to prevent violations of rights during data transfer to third parties.</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.5pt', background: 'white' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>4.</span></b><b><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;&nbsp; </span></b><b><u><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Rights of
                    Personal Data Owner (Relevant Person) under Article 11 of GDPR</span></u></b></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '11.5pt', background: 'white' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>As a personal
                    data owner, you can request your rights stated below from Global Assessment, the
                    data controller, at any time, pursuant to Article 11 of the KVKK. The rights
                    you have in this context are as follows:</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Learning
                    whether personal data is processed,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>If personal
                    data has been processed, requesting information about it,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Learning the
                    purpose of processing personal data and whether they are used in accordance
                    with its purpose,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Knowing the
                    third parties to whom personal data is transferred at home or abroad,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Requesting
                    correction of personal data in case of incomplete or incorrect processing and
                    requesting notification of the transaction made within this framework to the
                    third parties to whom the personal data has been transferred,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Despite the
                    fact that it has been processed in accordance with the GDPR and other relevant
                    law provisions, in case the reasons requiring its processing are eliminated,
                    this data shall be deleted, destroyed or anonymized within 30 (thirty) days in
                    accordance with the Regulation on the Deletion, Destruction or Anonymization of
                    Personal Data. and the action taken in this context</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Requesting
                    notification of third parties to whom personal data has been transferred,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;Objecting
                    to the emergence of a result against the person himself/herself by analyzing
                    the processed data exclusively through automated systems,</span></p>
                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '53.4pt', textAlign: 'justify', textIndent: '-.25in', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: 'Wingdings', color: 'black' }}>ü</span><span lang="TR" style={{ fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;&nbsp;</span><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>It has the
                    right to demand the compensation of the damage in case of loss due to the
                    unlawful processing of personal data..</span></p>
                <p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'justify', lineHeight: '12.65pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>For
                    comprehensive information on the principles of protection and processing of
                    your personal data, you can review the Personal Data Protection and Processing
                    Policy at https://www.Globalassessment.com.tr/ .You can contact us provided that
                    you meet the minimum requirements. At the same time, you can benefit from the
                    application form created by our company for your convenience while using these
                    application methods. (Our Application Form, which was created to use in your
                    applications to us, is available at https://www.Globalassessment.com.tr))</span></b></p>
                <table className="MsoNormalTable" border={0} cellSpacing={0} cellPadding={0} align="left" style={{ borderCollapse: 'collapse', marginLeft: '6.75pt', marginRight: '6.75pt' }}>
                    <tbody><tr style={{ height: '26.65pt' }}>
                        <td style={{ border: 'solid windowtext 1.0pt', background: '#E5E5E5', padding: '0in 5.4pt 0in 5.4pt', height: '26.65pt' }}>
                            <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Application Method</span></b></p>
                        </td>
                        <td style={{ border: 'solid windowtext 1.0pt', borderLeft: 'none', background: '#E5E5E5', padding: '0in 5.4pt 0in 5.4pt', height: '26.65pt' }}>
                            <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif', color: 'black' }}>Address to
                                apply</span></b></p>
                        </td>
                    </tr>
                        <tr style={{ height: '39.7pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Personal Application (The
                                    application made by the Relevant Person or his/her authorized person by the
                                    'guardian/attorney' personally)</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><i><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Dünya Ticaret Merkezi Egs
                                    Business Park Blokları No:12/1 B1 Blok Kapı No 71, 34149 Bakırköy</span></i></p>
                            </td>
                        </tr>
                        <tr style={{ height: '28.9pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '28.9pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Application Via Notary
                                    Public</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '28.9pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><i><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Dünya Ticaret Merkezi Egs
                                    Business Park Blokları No:12/1 B1 Blok Kapı No 71, 34149 Bakırköyveli</span></i></p>
                            </td>
                        </tr>
                        <tr style={{ height: '39.7pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Application with Secure
                                    Electronic Signature [Application via Registered Electronic Mail (KEP) by
                                    signing with a secure electronic signature]</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '39.7pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>………</span></p>
                            </td>
                        </tr>
                        <tr style={{ height: '37.6pt' }}>
                            <td style={{ border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '37.6pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>Application by Mobile
                                    Signature or E-mail [Using the secure e-mail address previously notified to
                                    the data controller by the data controller and registered in the data
                                    controller's system]</span></p>
                            </td>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '37.6pt' }}>
                                <p className="MsoNormal" style={{ textAlign: 'justify', lineHeight: '11.75pt' }}><b><span lang="TR" style={{ fontFamily: '"Times New Roman",serif' }}>info@Globalassessment.com.tr</span></b></p>
                            </td>
                        </tr>
                    </tbody></table>
                <p className="MsoNormal" style={{ lineHeight: '11.75pt' }}><span lang="TR" style={{ color: 'black' }}>&nbsp;</span></p>
                <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
                <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
                <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
            </div>


        </div>

    )



}


export default Turkishtos1