import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function SpaWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


              <p>
                    La parte de escritura incluye <span className = 'text-red-600'> {read.user.noq.writing} </span> Preguntas y tiene 5 minutos para responder a cada una de ellas.Si se alcanza la hora y no envía su respuesta, la solicitud se moverá automáticamente a la siguiente pregunta.
                </p>
                <br />
                <p>
                    Verá el temporizador de las preguntas relacionadas en la parte superior derecha de la aplicación y su cámara se grabe en la parte superior izquierda.La parte de escritura tomará <span className = 'text-red-600'> Un total de {read.user.noq.writing * 5} minutos para responder todas las preguntas. </span> Tómese su tiempo.
                </p>
                <br />
                <p>
                        Debe escribir al menos <span className = 'text-red-600'> 80 </span> Las palabras están dentro del rango aceptable de nuestra evaluación.No puede copiar ni pegar ningún material, grande o pequeño de ninguna otra fuente.
                </p>
                <br />
                <p>
                    Si no puede escribir, puede omitir la pregunta y perder el punto.
                </p>
                <br />
                <p>
                    Su monitor, movimiento y ojos se están registrando.Recibirá un 0 si abre una solicitud de terceros o engaña en cualquier tipo de formulario.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Si comprende la instrucción, puede presionar, entiendo y comenzar la parte de escritura.
                </h1>

            </div>

        </>
    )



}


export default SpaWriting