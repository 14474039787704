import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function DutWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Het schrijfgedeelte omvat <span className='text-red-600'> {read.user.noq.writing} </span> Vragen en u hebt 5 minuten om elk van hen te beantwoorden.Als de tijd is bereikt en u uw antwoord niet indient, gaat de aanvraag automatisch naar de volgende vraag.
                </p>
                <br />
                <p>
                    U ziet de timer voor de gerelateerde vragen rechtsboven op de applicatie en uw camera linksboven opgenomen.Het schrijfgedeelte neemt <span className='text-red-600'> een totaal van {read.user.noq.writing * 5} minuten om alle vragen te beantwoorden. </span> Neem de tijd.
                </p>
                <br />
                <p>
                    U moet op zijn minst <span className='text-red-600'> 80 </span> Woorden schrijven binnen het acceptabele bereik van onze beoordeling.U mag geen materiaal kopiëren of plakken, groot of klein uit andere bronnen.
                </p>
                <br />
                <p>
                    Als u niet kunt schrijven, kunt u de vraag overslaan en het punt verbeuren.
                </p>
                <br />
                <p>
                    Uw monitor, beweging en ogen worden opgenomen.U ontvangt een 0 als u een aanvraag van derden opent of vals speelt in enig formulier.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Als u de instructie begrijpt, kunt u drukken, begrijp ik het schrijfgedeelte.
                </h1>

            </div>

        </>
    )



}


export default DutWriting