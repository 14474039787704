import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Welcome() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">


                <h1 className=' text-3xl'>
                    welcome,
                </h1>
                <p>
                    This exam includes {read.totalQuestions} questions and you have 60 seconds to answer reading and listening questions and varibale time for speaking and writing questions.
                </p>
                <br />
                <p>
                    You will see the timer for the related questions on the top right of the application and your webcam being recorded on the top left. The exam automatically finishes after 60 mins if you remain idle. If so, you will lose your chance to take the same exam again.
                </p>
                <br />
                <p>
                    You need to read and agree with our terms of service and you must have active WEBCAM and MICROPHONE in order to take the exam.
                </p>
                <br />
                <p>
                    If your WEBCAM is not working and you cannot see yourself or the MICROPHONE is not connected, the start button will not be activated and you cannot start the exam. You must absolutely make sure you have a working WEBCAM and MICROPHONE prior to taking the test.
                </p>
                <br />
                <p>
                    You must ensure your Internet connection is working properly since we are recording your voice and your video. If you are disconncted, you can use the same link to continue the exam from where you left off.
                </p>
                <br />
                <p>
                    There is no negative point for any of the multiple choice questions, therefore feel free to answer all the questions as much as you can.
                </p>
                <br />
                <h2 className=' text-2xl text-red-600 font-bold'>
                It is needless to say, any cheating including using CHATGPT, other AIs, TRANSLATOR, switching windows, changing tabs, getting help from a friend or family member, or sharing your screen with another person is strictly FORBIDDEN and will result in disqualification and possible career damage.
                </h2>
                <br />
                <h1 className=' text-2xl'>
                    I UNDERSTAND will start the exam and trigger the timer for you. Good luck 😊.
                </h1>

            </div>

        </>
    )



}


export default Welcome