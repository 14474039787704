import "../App.css";
import React from "react";
import { write, useSnap } from "../store";
import Rec from "../recordings/rec";
import SingleQ from "./singleQ";
// import Modal from "../components/Modal";
import NewModal from "../components/newModal";
import { locale } from "../components/locale";

function Questions() {
  const read = useSnap();


  React.useEffect(() => {
    if(read.questions[read.currentQuestion - 1]?.questionType !== 'speaking' && read.questions[read.currentQuestion - 1]?.questionType !== 'listening'){
      write.stopTimer = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read.currentQuestion]);


  // console.log('🧯🧯' , read.page)

  const RenderIt = React.useCallback(() => {
    // console.log(read.totalQuestions , read.currentQuestion)
    if (read.questions[read.currentQuestion - 1].questionType === "reading") {
      write.currentQuestionType = `${
        locale[read.user.language].titles.reading
      }📖`;
    }
    if (read.questions[read.currentQuestion - 1].questionType === "listening") {
      write.currentQuestionType = `${
        locale[read.user.language].titles.listening
      }👂`;
    }
    if (read.questions[read.currentQuestion - 1].questionType === "writing") {
      write.currentQuestionType = `${
        locale[read.user.language].titles.writing
      }✍️`;
    }
    if (read.questions[read.currentQuestion - 1].questionType === "speaking") {
      write.currentQuestionType = `${
        locale[read.user.language].titles.speaking
      }🗣️`;
    }
    return (
      <>
        <SingleQ
          questionData={read.questions[read.currentQuestion - 1]}
          type={read.questions[read.currentQuestion - 1].questionType}
        />
        {/* <Modal type = {read.questions[read.currentQuestion - 1].questionType} /> */}
        <NewModal
          type={read.questions[read.currentQuestion - 1].questionType}
          title={`${
            read.questions[read.currentQuestion - 1].questionType
          } section instruction`}
        />
      </>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read.currentQuestion]);


  if (read.OS === "iOS" || read.OS === "Android") {
        // const vTrack = read.stream.getVideoTracks()[0];
        setInterval(async () => {
          const camPerm = await navigator.permissions.query({ name: "camera" });
          // console.log('zzzzzzzzzzzzz', camPerm.state)
          camPerm.state !== 'granted' && (write.page = 'CAMMICOFF')
        }, 3000);
      }


      React.useEffect(() => {
    let visibilityHidden, visibilityChange;
    // Opera 12.10 and Firefox 18 and later support
    if (typeof document.hidden !== "undefined") {
      visibilityHidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
      visibilityHidden = "mozHidden";
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      visibilityHidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      visibilityHidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    function fn() {
      if (document[visibilityHidden]) {
        write.tabControl++
      }
    }

    document.addEventListener(visibilityChange, fn)
    return () => window.removeEventListener(visibilityChange, fn);
  }, []);


  // console.log('🙈🙈🙈🙈', read.tabControl , `w-${Math.ceil(read.currentQuestion / read.totalQuestions)}/12`)



  return (
    <>
      <pre className=" text-3xl z-50 text-center">
        {read.currentQuestionType}
      </pre>
      <div className="bg-gray-200 rounded-full dark:bg-gray-700 relative">
        <div
          className={`bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`}
          style={{
            // width: `${(read.currentQuestion * 100) / read.totalQuestions}%`,
            width : `${((read.currentQuestion * 100) / read.totalQuestions) < 5 ? '5%' : `${(read.currentQuestion * 100) / read.totalQuestions}%`}`
          }}
        >
          {`${read.currentQuestion} / ${read.totalQuestions}`}
        </div>
      </div>
      <div className="flex flex-col items-center  md:max-w-full">
       { read.questions[read.currentQuestion - 1] && <RenderIt />}
      </div>
      <Rec />
    </>
  );
}

export default Questions;

//  {/* <SingleQ questionData={read.questions[read.currentQuestion - 1]} />  */}

//     {/* read.questions.map((q , i)=>{
//         return(
//          (read.currentQuestion - 1) === i && <SingleQ key={i} questionData={q} />
//         )
//       }) */}
