import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function RussianWelcome() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">

            <h1 className='text-3xl'>
                     добро пожаловать,
                 </h1>
                 <p>
                     Этот экзамен включает в себя {read.totalQuestions} вопросов, и у вас есть 60 секунд, чтобы ответить на вопросы по чтению и аудированию, а также различное время для устной и письменной речи.
                 </p>
                 <br />
                 <p>
                     Вы увидите таймер для соответствующих вопросов в правом верхнем углу приложения, а запись вашей веб-камеры — в левом верхнем углу.
                 </p>
                 <br />
                 <p>
                     Вам необходимо прочитать и согласиться с нашими условиями обслуживания, а для сдачи экзамена у вас должны быть активны веб-камера и микрофон.
                 </p>
                 <br />
                 <p>
                     Если ваша веб-камера не работает и вы не видите себя или микрофон подключен, кнопка запуска не будет активирована, и вы не сможете начать экзамен. Вы должны обязательно убедиться, что у вас есть рабочая веб-камера и микрофон, прежде чем сдавать экзамен.
                 </p>
                 <br />
                 <p>
                     Вы должны убедиться, что с вашим интернет-соединением все в порядке, так как мы записываем ваш голос и ваше видео. Если вы отключились, вы можете использовать ту же ссылку, чтобы продолжить экзамен с того места, где вы остановились.
                 </p>
                 <br />
                 <p>
                     Ни в одном из вопросов с несколькими вариантами ответов нет отрицательных моментов, поэтому не стесняйтесь отвечать на все вопросы столько, сколько сможете.
                 </p>
                 <br />
                 <h1 className='text-2xl'>
                    Если вы поняли инструкцию, вы можете нажать «Я понимаю» и начать экзамен. Удачи!
                 </h1>

             </div>
        </>
    )



}


export default RussianWelcome