import '../App.css';
import React from 'react';
import { write, useSnap } from '../store'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import toast from 'react-hot-toast';
import { pack } from 'msgpackr';
// import { WsContext } from '../App'

function CD() {
    const read = useSnap()
    // const ws = React.useContext(WsContext);
    const ws = read.ws
    const [timer, setTimer] = React.useState(60);

    React.useEffect(() => {
        const type = read.questions[read.currentQuestion - 1].questionType

        switch (type) { // ALL SHOULD GO BACK TO THEIR RESPECTIVE POSITIONS
            case 'speaking':
                setTimer(90)
                break;
            case 'writing':
                setTimer(600)
                break;
            case 'reading':
                setTimer(60)
                break;
            case 'listening':
                setTimer(60)
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const determineData = React.useCallback((type) => {
        switch (type) {
            case 'speaking':
                return `${read.id}-speaking-${read.questions[read.currentQuestion - 1].qID}.mp4`
            case 'writing':
                return read.writingAllWords
            default:
                return 'out'
        }
    }, [read.currentQuestion, read.id, read.questions, read.writingAllWords])

    // console.log(read.instruction[read.questions[read.currentQuestion - 1].questionType] , !read.stopTimer , '✖️✖️✖️✖️')

    return (
        <CountdownCircleTimer

            onComplete={() => {
                if (read.totalQuestions >= read.currentQuestion && !read.loading) {
                    write.currentQuestion = read.currentQuestion + 1
                    ws.send(pack({
                        type: 'ONE_ANSWER',
                        data: determineData(read.questions[read.currentQuestion - 1].questionType),
                        index: read.currentQuestion - 1,
                        id: read.id
                    }))
                    // ws.send(pack({ type: 'ONE_ANSWER', id : read.id , data: e.target.value , index : read.currentQuestion - 1 }))
                    if (read.questions[read.currentQuestion - 1].questionType === 'speaking') {
                        console.log('⌚⌚⌚⌚⌚')
                        read.recorder.stop()
                        ws.send(pack({ type: 'REC_FINISHED', format: read.questions[read.currentQuestion - 1].qID }))
                    }
                    return { shouldRepeat: true, delay: .5  }
                } if (read.loading) {
                    toast.error('You are disconnected from the Internet ❌', { duration: Infinity })
                } else {
                    console.log('end of questions')
                }
            }}
            size={read.OS === 'PC' ? 100 : 70}
            isPlaying={(read.instruction[read.questions[read.currentQuestion - 1].questionType] && !read.stopTimer) ? true : false}
            duration={timer}
            colors={['#004777', '#F7B801', '#FF8C00', '#A30000']}
            colorsTime={[45, 35, 20, 0]}
        >
            {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>

    );
}

export default CD;











// return (
//     <CountdownCircleTimer
//         onComplete={() => {
//             if (read.totalQuestions >= read.currentQuestion && !read.loading) {
//                 write.currentQuestion = read.currentQuestion + 1
//                 ws.send(pack({
//                     type: 'ONE_ANSWER',
//                     data: read.questions[read.currentQuestion - 1].questionType !== 'speaking' ? `out` : `${read.id}-speaking-${read.questions[read.currentQuestion - 1].qID}.mp4`,
//                     index: read.currentQuestion - 1
//                 }))
//                 if(read.questions[read.currentQuestion - 1].questionType === 'speaking'){
//                     ws.send(pack({ type: 'REC_FINISHED', format: read.questions[read.currentQuestion - 1].qID }))
//                 }
//                 return { shouldRepeat: true, delay: .5 }
//             } if (read.loading) {
//                 toast.error('You are disconnected from the Internet ❌', { duration: Infinity })
//             } else {
//                 console.log('end of questions')
//             }
//         }}
//         size={read.OS === 'PC' ? 100 : 70}
//         isPlaying={(read.instruction[read.questions[read.currentQuestion - 1].questionType] && !read.stopTimer) ? true : false}
//         duration={timer}
//         colors={['#004777', '#F7B801', '#FF8C00', '#A30000']}
//         colorsTime={[45, 35, 20, 0]}
//     >
//         {({ remainingTime }) => remainingTime}
//     </CountdownCircleTimer>

// );
// }

// export default CD;