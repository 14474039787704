import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function RusSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Говорящая часть включает в себя <span className='text-red-600'> {read.user.noq.speaking} </span> вопросы, и у вас есть 3 минуты, чтобы ответить на каждый из них.
                </p>
                <br />
                <p>
                    Вы увидите таймер для связанных вопросов в правом верхнем углу приложения, а камера записана в верхней части левого.Говорящая часть займет <span className='text-red-600'> В общей сложности {read.user.noq.speaking * 3} минут, чтобы ответить на все вопросы. </span> Пожалуйста, не торопитесь.
                </p>
                <br />
                <p>
                    Вы должны говорить по крайней мере <span className='text-red-600'> 1 </span> минута, чтобы быть в пределах приемлемого диапазона нашей оценки.
                </p>
                <br />
                <p>
                    <span className='Text-Red-600'> Вы не можете прочитать из письменного текста или запоминаемой речи </span>.Нам нужно услышать ваш голос и ваш голос в одиночестве без помощи кого -либо вокруг вас.
                </p>
                <br />
                <p>
                    Когда вы будете готовы, вы должны нажать кнопку записи 🔴, и когда вы закончите, вам следует 🟥 остановить запись.Если вы не нажмете кнопку «Стоп», приложение автоматически завершит запись через определенное время.
                </p>
                <br />
                <p>
                    Ваш монитор, движение и глаза записываются.Вы получите 0, если откроете третье лицо или обманывают в какой -либо форме.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Если вы понимаете инструкцию, вы можете нажать, я понимаю, и начать говорящую часть.
                </h1>

            </div>

        </>
    )



}


export default RusSpeaking