import Welcome from './locale/welcome'
import Bienvenu from './locale/bienvenu'
import Willkommen from './locale/Willkommen'
import Bienvenida from './locale/bienvenida'
import RussianWelcome from './locale/RussiaWelcome'
import ArabicWelcome from './locale/ArabicWelcome'
import KoreanWelcome from './locale/KoreanWelcome'
import Welkom from './locale/Welkom'
import Hosgeldiniz from './locale/hosgeldeniz'
import EngReading from './locale/engReading'
import EngListening from './locale/engListening'
import EngWriting from './locale/engWriting'
import EngSpeaking from './locale/engSpeaking'
import FreReading from './locale/freReading'
import FreListening from './locale/freListening'
import FreSpeaking from './locale/freSpeaking'
import FreWriting from './locale/freWriting'
import GerReading from './locale/gerReading'
import GerListening from './locale/gerListening'
import GerWriting from './locale/gerWriting'
import GerSpeaking from './locale/gerSpeaking'
import SpaSpeaking from './locale/spaSpeaking'
import SpaWriting from './locale/spaWriting'
import SpaReading from './locale/spaReading'
import SpaListening from './locale/spaListening'
import RusListening from './locale/rusListening'
import RusReading from './locale/rusReading'
import RusSpeaking from './locale/rusSpeaking'
import RusWriting from './locale/rusWriting'
import AraWriting from './locale/araWriting'
import AraListening from './locale/araListening'
import AraReading from './locale/araReading'
import AraSpeaking from './locale/araSpeaking'
import KorSpeaking from './locale/korSpeaking'
import KorListening from './locale/korListening'
import KorWriting from './locale/korWriting'
import KorReading from './locale/korReading'
import DutReading from './locale/dutReading'
import DutWriting from './locale/dutWriting'
import DutListening from './locale/dutListening'
import DutSpeaking from './locale/dutSpeaking'
import TurSpeaking from './locale/turSpeaking'
import TurWriting from './locale/turWriting'
import TurReading from './locale/turReading'
import TurListening from './locale/turListening'
import WelcomeItalian from './locale/welcomeItalian'
import ItaReading from './locale/itaReading'
import ItaSpeaking from './locale/itaSpeaking'
import ItaWriting from './locale/itaWriting'
import ItaListening from './locale/itaListening'

export const locale = {

    English: {
        button: {
            start: 'START THE TEST',
            understand: 'I UNDERSTAND',
            agree: 'I agree with the',
            terms: 'terms and conditions',
            consent: 'I consent to the',
            usage: 'usage of my data',
            end: 'Your test is concluded. Thanks',
            send: 'Send',
            moreWords: 'more words needed',
            wordCount: 'Word Count',
            skip: 'skip',
            twoDevices1: 'You cannot login with 2 devices at the same time.',
            twoDevices2: 'Make sure you have closed the application on your other devices and refresh this page.',
            linkEXP1: 'Link is expired',
            linkEXP2: 'Please call the company if you believe this is a mistake.',
            stopRec : 'Your webcam is being recorded, click on 🟥 to stop',
            speakingTimer : 'You have 45 seconds to prepare for the speaking part, after 45 seconds the recording will automatically start. You may also begin sooner by clicking the 🎙️ microphone to start recording.'
        },
        instruction: {
            welcome: <Welcome />,
            reading: <EngReading />,
            listening: <EngListening />,
            writing: <EngWriting />,
            speaking: <EngSpeaking />,
        },
        titles: {
            speaking: 'speaking',
            reading: 'reading',
            listening: 'listening',
            writing: 'writing'
        }
    },
    French: {
        button: {
            start: 'commencer l\'essai',
            understand: 'je comprends',
            agree: 'Je suis d\'accord avec les',
            terms: 'termes et conditions',
            consent: 'Je consens à l\'utilisation',
            usage: 'de mes données',
            end: 'Votre test est conclu. Merci',
            send: 'Envoyer',
            moreWords: 'plus de mots nécessaires',
            wordCount: 'nombre de mots',
            skip: 'sauter',
            twoDevices1: "«Vous ne pouvez pas vous connecter avec 2 appareils en même temps.»",
            twoDevices2: "«Assurez-vous d'avoir fermé l'application sur vos autres appareils et actualiser cette page.»",
            linkEXP1: "«le lien est expiré»",
            linkEXP2: "«Veuillez appeler l'entreprise si vous pensez que c'est une erreur.",
            stopRec : 'Votre webcam enregistre, cliquez sur 🟥 pour arrêter',
            speakingTimer : 'Vous avez 45 secondes pour vous préparer à la partie parlante, après 45 secondes, l\'enregistrement commencera automatiquement.Vous pouvez également commencer plus tôt en cliquant sur le microphone 🎙️ pour commencer l\'enregistrement.'
        },
        instruction: {
            welcome: <Bienvenu />,
            reading: <FreReading />,
            listening: <FreListening />,
            writing: <FreWriting />,
            speaking: <FreSpeaking />,
        },
        titles: {
            speaking: 'Parlant',
            reading: 'lisant',
            listening: 'écoute',
            writing: 'écrivant'
        }
    },
    German: {
        button: {
            start: 'Test starten',
            understand: 'Ich verstehe',
            agree: 'Ich stimme den',
            terms: 'Nutzungsbedingungen zu',
            consent: 'Ich stimme der Verwendung',
            usage: 'meiner Daten zu',
            end: 'Ihr Test ist abgeschlossen. Danke',
            send: 'schicken',
            moreWords: 'mehr Wörter benötigt',
            wordCount: 'Wörter zählen',
            skip: 'überspringen',
            twoDevices1: 'Sie können sich nicht gleichzeitig mit 2 Geräten anmelden.',
            twoDevices2: 'Stellen Sie sicher, dass Sie die Anwendung auf Ihren anderen Geräten geschlossen haben, und aktualisieren Sie diese Seite.',
            linkEXP1: 'Link ist abgelaufen',
            linkEXP2: 'Bitte rufen Sie das Unternehmen an, wenn Sie glauben, dass dies ein Fehler ist.',
            stopRec : 'Ihre Webcam zeichnet auf. Klicken Sie zum Stoppen auf 🟥',
            speakingTimer : 'Sie haben 45 Sekunden Zeit, um sich auf das Sprechpart vorzubereiten, nach 45 Sekunden wird die Aufnahme automatisch beginnt.Sie können auch früher beginnen, indem Sie auf das 🎙️ -Mikrofon klicken, um mit der Aufzeichnung zu beginnen.'
        },
        instruction: {
            welcome: <Willkommen />,
            reading: <GerReading />,
            listening: <GerListening />,
            writing: <GerWriting />,
            speaking: <GerSpeaking />,
        },
        titles: {
            speaking: 'Sprechen',
            reading: 'Lektüre',
            listening: 'Hören',
            writing: 'Schreiben'
        }
    },
    Spanish: {
        button: {
            start: 'iniciar la prueba',
            understand: 'entiendo',
            agree: 'Acepto los',
            terms: 'términos y condiciones',
            consent: 'Yo consentiré en el',
            usage: 'Uso de mis datos',
            end: 'Su prueba ha concluido. Gracias',
            send: 'enviar',
            moreWords: 'se necesitan más palabras',
            wordCount: 'el recuento de palabras',
            skip: 'omitir',
            twoDevices1: 'No puede iniciar sesión con 2 dispositivos al mismo tiempo.',
            twoDevices2: 'Asegúrese de haber cerrado la aplicación en sus otros dispositivos y actualizar esta página.',
            linkEXP1: 'El enlace está caducado',
            linkEXP2: 'Llame a la empresa si cree que esto es un error.',
            stopRec : 'Tu cámara web está grabando, haz clic en 🟥 para detener',
            speakingTimer : 'Tiene 45 segundos para prepararse para la parte de oratoria, después de 45 segundos, la grabación comenzará automáticamente.También puede comenzar antes haciendo clic en el micrófono 🎙️ para comenzar a grabar.'
        },
        instruction: {
            welcome: <Bienvenida />,
            reading: <SpaReading />,
            listening: <SpaListening />,
            writing: <SpaWriting />,
            speaking: <SpaSpeaking />,
        },
        titles: {
            speaking: 'discurso',
            reading: 'lectura',
            listening: 'escuchando',
            writing: 'escribiendo'
        }
    },
    Russian: {
        button: {
            start: 'начать экзамен',
            understand: 'я понимаю',
            agree: 'Я согласен с',
            terms: 'условия и положения',
            consent: 'Я даю согласие на',
            usage: 'использование моих данных',
            end: 'Ваш тест завершен. Спасибо',
            send: 'отправлять',
            moreWords: 'нужно больше слов',
            wordCount: 'количество слов',
            skip: 'пропускать',
            twoDevices1: 'Вы не можете войти в систему с 2 устройствами одновременно.',
            twoDevices2: 'Убедитесь, что вы закрыли приложение на других устройствах и обновите эту страницу.',
            linkEXP1: 'Ссылка истек',
            linkEXP2: 'Пожалуйста, позвоните в компанию, если вы считаете, что это ошибка.',
            stopRec : 'Ваша веб-камера записывает, нажмите 🟥, чтобы остановить',
            speakingTimer : 'У вас есть 45 секунд, чтобы подготовиться к говорящей части, через 45 секунд запись автоматически начнется.Вы также можете начать раньше, нажав на микрофон, чтобы начать запись.'
        },
        instruction: {
            welcome: <RussianWelcome />,
            reading: <RusReading />,
            listening: <RusListening />,
            writing: <RusWriting />,
            speaking: <RusSpeaking />,
        },
        titles: {
            speaking: 'Говорящий',
            reading: 'чтение',
            listening: 'слушаю',
            writing: 'письмо'
        }
    },
    Arabic: {
        button: {
            start: 'ابدأ الاختبار',
            understand: 'أفهم',
            agree: 'أتفق مع',
            terms: 'الأحكام والشروط',
            consent: 'أوافق على',
            usage: 'استخدام بياناتي',
            end: 'انتهى اختبارك. شكرًا',
            send: 'إرسال',
            moreWords: 'المزيد من الكلمات المطلوبة',
            wordCount: 'عدد الكلمات',
            skip: 'حذف',
            twoDevices1: 'لا يمكنك تسجيل الدخول باستخدام جهازين في نفس الوقت.',
            twoDevices2: 'تأكد من أنك أغلقت التطبيق على أجهزتك الأخرى وتحديث هذه الصفحة.',
            linkEXP1: 'انتهت صلاحية الرابط',
            linkEXP2: 'يرجى الاتصال بالشركة إذا كنت تعتقد أن هذا خطأ.',
            stopRec : 'كاميرا الخاصة بك تقوم بالتسجيل، انقر على 🟥 للإيقاف',
            speakingTimer : 'لديك 45 ثانية للتحضير لجزء التحدث ، بعد 45 ثانية ، سيبدأ التسجيل تلقائيًا.يمكنك أيضًا البدء عاجلاً بالنقر فوق الميكروفون 🎙 لبدء التسجيل.'
        },
        instruction: {
            welcome: <ArabicWelcome />,
            reading: <AraReading />,
            listening: <AraListening />,
            writing: <AraWriting />,
            speaking: <AraSpeaking />,
        },
        titles: {
            speaking: 'تكلم',
            reading: 'قراءة',
            listening: 'الاستماع',
            writing: 'كتابة'
        }
    },
    Korean: {
        button: {
            start: '시험을 시작하다',
            understand: '동의합니다',
            agree: '저는 동의한다',
            terms: '이용 약관',
            consent: '나는 동의한다',
            usage: '내 데이터 사용',
            end: '테스트가 끝났습니다. 감사해요',
            send: '보내기',
            moreWords: '더 많은 단어 필요',
            wordCount: '단어 수',
            skip: '질문 건너뛰기',
            twoDevices1: '동시에 2 개의 장치로 로그인 할 수 없습니다.',
            twoDevices2: '다른 장치의 응용 프로그램을 닫고이 페이지를 새로 고치십시오.',
            linkEXP1: '링크가 만료되었습니다',
            linkEXP2: '이것이 실수라고 생각되면 회사에 전화하십시오.',
            stopRec : '웹캠이 녹화 중입니다. 중지하려면 🟥을 클릭하세요.',
            speakingTimer : '말하기 부분을 준비하는 데 45 초가 걸리며 45 초 후에 녹음이 자동으로 시작됩니다.녹음을 시작하기 위해 🎙️ 마이크를 클릭하여 더 빨리 시작할 수도 있습니다.'
        },
        instruction: {
            welcome: <KoreanWelcome />,
            reading: <KorReading />,
            listening: <KorListening />,
            writing: <KorWriting />,
            speaking: <KorSpeaking />,
        },
        titles: {
            speaking: '말하기',
            reading: '독서',
            listening: '청취',
            writing: '글쓰기'
        }
    },
    Dutch: {
        button: {
            start: 'start de proef',
            understand: 'ik begrijp',
            agree: 'Ik ben het eens met de',
            terms: 'voorwaarden',
            consent: 'Ik instem met de',
            usage: 'Gebruik van mijn gegevens',
            end: 'Uw test is afgerond. Bedankt',
            send: 'Sturen',
            moreWords: 'meer woorden nodig',
            wordCount: 'Aantal woorden',
            skip: 'overslaan',
            twoDevices1: 'U kunt niet tegelijkertijd inloggen met 2 apparaten.',
            twoDevices2: 'Zorg ervoor dat u de applicatie op uw andere apparaten hebt gesloten en deze pagina vernieuwt.',
            linkEXP1: 'Link is verlopen',
            linkEXP2: 'Bel het bedrijf als u denkt dat dit een vergissing is.',
            stopRec : 'Je webcam neemt op, klik op 🟥 om te stoppen',
            speakingTimer : 'Je hebt 45 seconden om je voor te bereiden op het spreekonderdeel, na 45 seconden start de opname automatisch.U kunt ook eerder beginnen door op de 🎙️ Microfoon te klikken om op te nemen.'
        },
        instruction: {
            welcome: <Welkom />,
            reading: <DutReading />,
            listening: <DutListening />,
            writing: <DutWriting />,
            speaking: <DutSpeaking />,
        },
        titles: {
            speaking: 'spreken',
            reading: 'lezing',
            listening: 'luisteren',
            writing: 'schrijven'
        }
    },
    Turkish: {
        button: {
            start: 'Başlat',
            understand: 'okudum ve anladım',
            agree: 'kabul ediyorum',
            terms: 'Şartlar ve koşulları',
            consent: 'izin veriyorum',
            usage: 'verilerimin kullanılmasına',
            end: 'testiniz bitti, teşekkürler',
            send: 'gönder',
            moreWords: 'daha fazla kelime gerekli',
            wordCount: 'sözcük sayısı',
            skip: 'atla',
            twoDevices1: 'Aynı anda 2 cihazla giriş yapamazsınız.',
            twoDevices2: 'Uygulamayı diğer cihazlarınızdaki kapattığınızdan ve bu sayfayı yenilediğinizden emin olun.',
            linkEXP1: 'Bağlantı süresi doldu',
            linkEXP2: 'Bunun bir hata olduğuna inanıyorsanız lütfen şirketi arayın.',
            stopRec : 'kameranız kayıt yapıyor, durdurmak için 🟥\'e tıklayın',
            speakingTimer : 'Konuşma bölümüne hazırlanmak için 45 saniyeniz var, 45 saniye sonra kayıt otomatik olarak başlayacak.Ayrıca, kayda başlamak için 🎙️ mikrofonuna tıklayarak daha erken başlayabilirsiniz.'
        },
        instruction: {
            welcome: <Hosgeldiniz />,
            reading: <TurReading />,
            listening: <TurListening />,
            writing: <TurWriting />,
            speaking: <TurSpeaking />,
        },
        titles: {
            speaking: 'konuşma',
            reading: 'Okuma',
            listening: 'dinleme',
            writing: 'yazı'
        }
    },
    Italian: {
        button: {
            start: 'Avvia il test',
            understand: 'capire',
            agree: 'd\'accordo',
            terms: 'Termini e Condizioni',
            consent: 'sono d\'accordo ',
            usage: 'utilizzo dei miei dati',
            end: 'il tuo test è terminato, grazie',
            send: 'inviare',
            moreWords: 'sono necessarie più parole',
            wordCount: 'numero di parole',
            skip: 'saltare',
            twoDevices1: 'Non è possibile accedere con 2 dispositivi contemporaneamente.',
            twoDevices2: 'Assicurati di aver chiuso l\'applicazione su altri dispositivi e aggiorna questa pagina.',
            linkEXP1: 'Il collegamento è scaduto',
            linkEXP2: 'Si prega di chiamare l\'azienda se ritieni che questo sia un errore.',
            stopRec : 'La tua webcam sta registrando, fai clic su 🟥 per interrompere',
            speakingTimer : 'Hai 45 secondi per prepararti per la parte di conversazione, dopo 45 secondi la registrazione inizierà automaticamente.Puoi anche iniziare prima facendo clic sul microfono 🎙️ per iniziare a registrare.'
        },
        instruction: {
            welcome: <WelcomeItalian />,
            reading: <ItaReading />,
            listening: <ItaListening />,
            writing: <ItaWriting />,
            speaking: <ItaSpeaking />,
        },
        titles: {
            speaking: 'orale',
            reading: 'Comprensione della lettura',
            listening: 'ascolto',
            writing: 'scrittura'
        }
    },
}