import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Bienvenu() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">


                <h1 className=' text-3xl'>
                    Bienvenue ,
                </h1>
                <p>
                    Cet examen comprend des questions {read.totalQuestions} et vous disposez de 60 secondes pour répondre aux questions de lecture et d'écoute et d'un temps variable pour les questions d'expression orale et écrite.
                </p>
                <br />
                <p>
                    Vous verrez la minuterie pour les questions connexes en haut à droite de l'application et votre webcam en cours d'enregistrement en haut à gauche.
                </p>
                <br />
                <p>
                    Vous devez lire et accepter nos conditions d'utilisation et vous devez disposer d'une webcam et d'un microphone actifs pour passer l'examen.
                </p>
                <br />
                <p>
                    Si votre webcam ne fonctionne pas et que vous ne pouvez pas vous voir ou que le microphone est maintenant connecté, le bouton de démarrage ne sera pas activé et vous ne pourrez pas démarrer l'examen. Vous devez absolument vous assurer que vous disposez d'une webcam et d'un microphone fonctionnels avant de passer l'examen.
                </p>
                <br />
                <p>
                    Vous devez vous assurer que votre connexion Internet est correcte puisque nous enregistrons votre voix et votre vidéo. Si vous êtes déconnecté, vous pouvez utiliser le même lien pour reprendre l'examen là où vous l'avez laissé.
                </p>
                <br />
                <p>
                    Il n'y a aucun point négatif pour aucune des questions à choix multiples, donc n'hésitez pas à répondre à toutes les questions autant que vous le pouvez.
                </p>
                <br />
                <h1 className=' text-2xl'>
                    Si vous comprenez les instructions, vous pouvez appuyer sur Je comprends et commencer l'examen. Bonne chance!
                </h1>

            </div>

        </>
    )



}


export default Bienvenu