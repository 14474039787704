import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function WelcomeItalian() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">


                <h1 className='text-3xl'>
                    benvenuto a valutazione della prova di italiano,
                </h1>
                <p>
                    Questo esame include {read.totalQuestions} Domande e hai 60 secondi per rispondere a domande di lettura e ascolto e tempo per parlare e scrivere domande.
                </p>
                <br />
                <p>
                    Vedrai il timer per le domande correlate in alto a destra dell'applicazione e la tua webcam registrata in alto a sinistra.L'esame termina automaticamente dopo 60 minuti se rimani inattivo.In tal caso, perderai la possibilità di sostenere di nuovo lo stesso esame.
                </p>
                <br />
                <p>
                    Devi leggere e concordare con i nostri termini di servizio e devi avere una webcam e un microfono attivo per sostenere l'esame.
                </p>
                <br />
                <p>
                    Se la tua webcam non funziona e non puoi vedere te stesso o il microfono è ora connesso, il pulsante di avvio non verrà attivato e non puoi iniziare l'esame.Devi assolutamente assicurarti di avere una webcam e un microfono funzionanti prima di sostenere l'esame.
                </p>
                <br />
                <p>
                    Devi assicurarti che la tua connessione Internet vada bene poiché stiamo registrando la tua voce e il tuo video.Se sei scollegato, puoi utilizzare lo stesso link per continuare l'esame da dove ti sei interrotto.
                </p>
                <br />
                <p>
                    Non esiste un punto negativo per nessuna delle domande a scelta multipla, quindi sentiti libero di rispondere a tutte le domande il più possibile.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Se capisci le istruzioni, puoi premere, capisco e avviare l'esame.Buona fortuna!
                </h1>

            </div>

        </>
    )



}


export default WelcomeItalian