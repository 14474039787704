import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function FreWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">

                <p>
                    La partie d'écriture comprend <span className='text-red-600'> {read.user.noq.writing} </span> et vous avez 5 minutes pour répondre à chacun d'eux.Si l'heure est atteinte et que vous ne soumettez pas votre réponse, la demande passera automatiquement à la question suivante.
                </p>
                <br />
                <p>
                    Vous verrez la minuterie pour les questions connexes en haut à droite de l'application et votre appareil photo enregistré en haut à gauche.La partie d'écriture prendra <span className='text-red-600'> un total de {read.user.noq.writing * 5} minutes pour répondre à toutes les questions. </span> Veuillez prendre votre temps.
                </p>
                <br />
                <p>
                    Vous devez écrire au moins <span className='text-red-600'> 80 </span> des mots pour être dans la plage acceptable de notre évaluation.Vous ne pouvez pas copier ou coller du matériel, grand ou petit à partir de toute autre source.
                </p>
                <br />
                <p>
                    Si vous ne pouvez pas écrire, vous pouvez ignorer la question et perdre le point.
                </p>
                <br />
                <p>
                    Votre moniteur, vos mouvements et vos yeux sont enregistrés.Vous recevrez un 0 si vous ouvrez une application tierce ou triche dans une sorte de formulaire.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Si vous comprenez l'instruction, vous pouvez appuyer sur Je comprends et démarrer la partie d'écriture.
                </h1>

            </div>

        </>
    )



}


export default FreWriting