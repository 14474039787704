/* eslint-disable react-hooks/exhaustive-deps */
import '../App.css';
import React from 'react';
// import { WsContext } from '../App'
import { pack } from 'msgpackr';
import { write, useSnap } from '../store'
import { ref } from 'valtio'
import Webcam from "react-webcam";


// let chunks = [] // must be on with experimental
let recorder
let canvasCtx
let canvasInterval

function Rec() {
    // const ws = React.useContext(WsContext);
    const read = useSnap()
    const ws = read.ws
    // const webcamRef = React.useRef()
    const webcamWRef = React.useRef()
    const screenRef = React.useRef()
    const canvasRef = React.useRef()
    const [hideWebcam, setHideWebcam] = React.useState(false)
    const [vWidth, setvWidth] = React.useState(1)
    const [vHeight, setvHeight] = React.useState(1)
    const [webcamStream, setWebcamStream] = React.useState()
    const [canvasStream, setCanvasStream] = React.useState()
    // const [screenStream, setScreanStream] = React.useState()




    const handleWMedia = React.useCallback(function handleWMedia(stream) {
        setWebcamStream(stream)
        // console.log(stream)
    }, [])



    React.useEffect(() => {
        canvasCtx = canvasRef.current.getContext('2d');

        if (webcamStream) {
            // webcamRef.current.srcObject = webcamStream
            // console.log('read.sStream', read.sStream)
            read.sStream && (screenRef.current.srcObject = read.sStream)
            const vTracks = webcamStream.getVideoTracks()[0]
            // console.log(vTracks, '🍀🍀🍀')
            vTracks.addEventListener("ended", () => {
                write.page = 'CAMMICOFF'
            });
            const { height, width } = vTracks.getSettings()
            let multiplier
            if (read.OS === 'PC') {
                multiplier = 30
            } else if (read.OS === 'iOS') {
                multiplier = 20
            } else {
                multiplier = 30
            }

            setvWidth(~~((width ? width : 1080) * multiplier) / 100)
            setvHeight(~~((height ? height : 2340) * multiplier) / 100)


            canvasInterval = setInterval(() => {
                canvasCtx.save();
                canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                canvasCtx.imageSmoothingQuality = "high";
                canvasCtx.drawImage(webcamWRef.current.video, 0, 0, canvasRef.current.width, canvasRef.current.height);

                canvasCtx.globalCompositeOperation = "source-over";
                canvasCtx.drawImage(screenRef.current, 0, 150, canvasRef.current.width / 2.5, canvasRef.current.height / 3.5);
            }, 55);


            // console.log(webcamStream.getVideoTracks()[0].getSettings(), width, height , canvasRef.current.width, canvasRef.current.height)


            const fps = (read.OS === 'iOS') ? 6 : 20
            // console.log('FPS', fps)
            const canvasStream = canvasRef.current.captureStream(fps);
            const audio = webcamStream.getTracks().find(el => el.kind === 'audio');
            // console.log(webcamWRef.current.video, webcamRef.current)
            canvasStream.addTrack(audio)
            setCanvasStream(canvasStream)
            write.canvasStreamStore = ref(canvasStream)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webcamStream]);

    // console.log(`vWidth`, vWidth, vHeight)

    function mR() {
        //random number between 1 and 10000
        const validator = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
        console.log('🍬🍬🍬 recorder renewed')
        let options
        if (MediaRecorder.isTypeSupported('video/webm; codecs=h264')) { //!h264 and why not?
            options = { mimeType: 'video/webm; codecs=h264' };
        } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
            options = { mimeType: 'video/webm; codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8')) {
            options = { mimeType: 'video/webm; codecs=vp8' };
        } else if (MediaRecorder.isTypeSupported('video/mp4; codecs=avc1')) {
            options = { mimeType: 'video/mp4; codecs=avc1' };
        } else if (MediaRecorder.isTypeSupported('video/x-matroska; codecs=avc1')) {
            options = { mimeType: 'video/x-matroska; codecs=avc1' };
        } else {
            options = { mimeType: 'video/mp4' };
        }
        console.log(options, 'OPTIONS')
        recorder = new MediaRecorder(canvasStream, options);
        write.recorder = ref(recorder) // we want to use the recoer in other places


        recorder.ondataavailable = async (e) => {
            // chunks.push(e.data);
            // console.log('there is a chunk', e.data , e.data.size, recorder.state)
            //random number between 1 an 1000
            // const random = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
            // console.log('❓❓', validator)
            const arbuf = await e.data.arrayBuffer()
            e.data.size && ws.send(pack({ type: 'REC', data: arbuf, options , validator }))
        }
        recorder.start(200) //! production must be on
    }



    React.useEffect(() => {
        let timer1
        if (canvasStream && !read.recorder) { // !read.recorder re new the instance for speaking section as it doesn't record with 1 instance.

            // setTimeout(() => {
            mR()
            // }, 1000);
            write.mR = ref(mR) //make it global
            // console.log(mR, '🎙️🎙️🎙️')
            timer1 = setTimeout(() => {
                recorder.stop()
                clearInterval(canvasInterval)
                console.log('🔚🔚🔚🔚🔚')
                ws.send(pack({ type: 'REC_FINISHED', format: 'ALL' }))
                ws.close()
            }, 7200000);
        }
        return () => {
            clearTimeout(timer1);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasStream]);


    React.useEffect(() => {
        return () => clearTimeout(canvasInterval); // to ensure that the inerval is stopped.
        // return () => ws.send(pack({ type: 'test'}))
    }, []);

    //experimental SAVING MP4 LOCALLY TO COMPARE WITH THE SERVER
    // React.useEffect(() => {
    //     if (read.currentQuestion > read.totalQuestions) {
    //         let blob = new Blob(chunks, { type: 'video/mp4' })
    //         console.log(`blob`, blob)
    //         const file = new File([blob], "1.mp4", { type: "video/mp4" });
    //         const url = URL.createObjectURL(file)
    //         console.log(file, url)
    //         const tempLink = document.createElement('a');
    //         tempLink.href = url;
    //         tempLink.setAttribute('download', 'filename.mp4');
    //         tempLink.click();
    //     }
    // }, [read.currentQuestion, read.totalQuestions]);
    //experimental

    React.useEffect(() => {
        if (read.currentQuestion > read.totalQuestions) {
            canvasInterval && clearInterval(canvasInterval)
        }
    }, [read.currentQuestion, read.totalQuestions]);

    return (
        <div className="App absolute top-0 left-0 z-0">

            <Webcam
                ref={webcamWRef}
                onClick={() => setHideWebcam(true)}
                className={`w-42 h-24 rounded-br-full ${hideWebcam && 'hidden'}`}
                audio={true}
                muted={true}
                onUserMedia={(e) => handleWMedia(e)}
                audioConstraints={{
                    deviceId: read.activeMic ? read.activeMic : undefined
                }}
                videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: "user",
                    deviceId: read.activeWebcam ? read.activeWebcam : undefined,
                }}
            />

            {
                hideWebcam ? <span onClick={() => setHideWebcam(false)}>🔲</span> : <span onClick={() => setHideWebcam(true)} className=' absolute right-0 top-0 text-xs'>❌</span>
            }

            {/* <video muted ref={screenRef} style={{ width: 300, height: 182, background: 'red', display: 'none' }} autoPlay /> */}
            <video className='w-1 invisible' muted ref={screenRef} autoPlay />
            {/* <video muted ref={screenRef} style={{width : 50}} autoPlay /> */}
            <canvas className='hidden w-1' width={vWidth} height={vHeight} ref={canvasRef} />
            {/* <canvas ref={canvasRef} className='w-28' width={vWidth} height={vHeight} />         */}
        </div>
    );
}

export default Rec;