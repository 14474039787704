import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function GerListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Der Hörteil enthält <span className='text-red-600'> {read.user.noq.listinging} </span> Fragen, und Sie haben 60 Sekunden Zeit, um jeweils zu beantworten.Die Fragen wiederholen sich nicht und wenn Ihnen die Zeit ausgeht, wechselt die Anwendung automatisch zur nächsten Frage.
                </p>
                <br />
                <p>
                    Sie sehen den Timer für die damit verbundenen Fragen oben rechts in der Anwendung und Ihre Kamera wird oben links aufgezeichnet.Der Hörteil nimmt <span className='text-red-600'> eine Summe von {read.user.noq.listening} Minuten, um alle Fragen zu beantworten. </span> Bitte nehmen Sie sich Zeit.
                </p>
                <br />
                <p>
                    Sie werden einen Wiedergabetaste sehen.Drücken Sie die Wiedergabetaste, wenn Sie bereit sind, zuzuhören.
                </p>
                <br />
                <p>
                    Der Timer beginnt, wenn Sie das Audio angehört haben.Das Audio wird nur einmal gespielt und Sie werden es nicht wieder hören können.Von dort aus haben Sie Zeit, eine der Antworten zu wählen.
                </p>
                <br />
                <p>
                    Es gibt keinen negativen Punkt für eine der Multiple -Choice -Fragen. Daher können Sie alle Fragen so viel wie möglich beantworten.
                </p>
                <br />
                <p>
                    Ihr Monitor, Ihre Bewegung und Ihre Augen werden aufgezeichnet.Sie erhalten eine 0, wenn Sie einen Drittantrag an Drittanbietern eröffnen oder in irgendeiner Form betrügen.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Wenn Sie die Anweisung verstehen, können Sie den Hörteil verstehe und starten.
                </h1>

            </div>

        </>
    )



}


export default GerListening