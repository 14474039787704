import "./App.css";
import React from "react";
import Lottie from "lottie-react";
import Questions from "./questions/questions";
import { write, useSnap } from "./store";
import Test from "./Test";
import { Toaster } from "react-hot-toast";
import WsListener from "./components/WsList";
import cone from "./assets/cone.json";
import end from "./assets/end.json";
import noentry from "./assets/noentry.json";
import { ref } from "valtio";
import { locale } from "./components/locale";
import { pack } from 'msgpackr';
// import NewModal from "./components/newModal";

function App() {
  const read = useSnap();
  const [wsState, setWsState] = React.useState(false);
  const [logo, setLogo] = React.useState(undefined);


  React.useEffect(() => {
    // const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    // console.log(contentRef.current.offsetHeight , documentHeight , '❌❌❌' ,((contentRef.current.clientHeight + 150) > documentHeight))
    // setFontSize(() => (contentRef.current.clientHeight + 150) > documentHeight ? 'text-lg' : 'text-2xl')
    if (read.saved) {
      read.currentQuestion > read.totalQuestions && (write.page = "DONE");
      write.saved = false;
      write.loading = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read.saved]);



  React.useEffect(() => {
    if (read.ws && read.user.company) {
      setLogo(read.user.company.replace(/\s+/g, '%20'))
    }
  }, [read.user.company, read.ws]);

  const connect = React.useCallback(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);
    write.ws = ref(ws);
    ws.onopen = () => {
      console.log('WS 📂')
      setWsState(true); // 1 means it's ready
    };
    ws.onclose = (e) => {
      write.page = 'DC'
      console.log(
        "Socket is closed.",
        e.reason
      );
    };
  }, []);

  React.useEffect(() => {
    connect();

    async function runIt() {
      //http://localhost:3000/?id=dynamidID < this is our start point
      write.id = window.location.href.split("=").at(-1);
      const instruction = { ...localStorage };
      // console.log(instruction);
      if (instruction) {
        instruction.tos1 && (write.instruction.tos1 = true);
        instruction.tos2 && (write.instruction.tos2 = true);
        instruction.welcome && (write.instruction.welcome = true);
        instruction.reading && (write.instruction.reading = true);
        instruction.listening && (write.instruction.listening = true);
        instruction.writing && (write.instruction.writing = true);
        instruction.speaking && (write.instruction.speaking = true);
      }
    }

    runIt(); //because React is stupid

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // console.log(read.page , '😆😆' , read.currentQuestionType)


  React.useEffect(() => {
    if (read.page === "DONE" && !read.currentQuestionType.includes('speaking')) {
      setTimeout(() => {
        // read.ws.send(pack({ type: 'REC_FINISHED', format: 'ALL' }))
        read.ws.close()
        read.ws.onclose = null
        console.log('🐟🐟🐟', 'closed')
      }, 1000);
    } else if (read.page === 'DONEREVISITED' || read.page === "DONE") {
      setTimeout(() => {
        read.ws.close()
        read.ws.onclose = null
        console.log('📪📪📪', 'closed')
      }, 1000);
    }
  }, [read.currentQuestion, read.page, read.currentQuestionType, read.ws]);

  const RenderIt = React.useCallback(() => {
    if (wsState && read.auth) {
      if (read.page === 'DC') return <p className=" text-xl text-justify"> 🛜✂️😔 You are disconnected from the Internet. You can refresh 🔃 the page and try again. İnternet bağlantınız kesildi. Sınavı tekrar denemeden önce lütfen internet bağlantınızın iyi olduğundan emin olun. 🔃 lütfen sayfayı yenileyin. 🛜✂️😔</p>
      if (read.page === "DONE") {
        read.recorder.state === 'recording' && read.recorder.stop()
        return (
          <>
            {" "}
            <Lottie className="w-52" animationData={end} loop={true} />{" "}
            <div className="w-9/12 text-4xl">
              {locale[read.user.language].button.end}
            </div>{" "}
          </>
        );
      }
      if (read.page === "DONEREVISITED") {
        read.recorder.state === 'recording' && read.recorder.stop()
        return (
          <>
            {" "}
            <Lottie className="w-52" animationData={end} loop={true} />{" "}
            <div className="w-9/12 text-4xl">
              There are no more questions, your answers are already received and you are all done here 😊.
            </div>{" "}
          </>
        );
      }

      if (read.page === "QUESTIONS") return <Questions />;
      if (read.page === "TEST") return <Test />;
      if (read.page === "CAMMICOFF") {
        setTimeout(() => {
          read.ws.send(pack({ type: 'REC_FINISHED', format: 'ALL' }))
        }, 555);
        return <p className="text-xl"> WEBCAM 📸 MUST REMAIN ON 🔌! PLEASE REFRESH THE PAGE AND PERMIT THE CAMERA ACCESS. </p>;
      }
      if (read.page === "DUPLICATE") return <p className="text-xl"> YOU HAVE ALREADY STARTED THIS TEST.PLEASE DISCONNECT OTHER DEVICES TO CONTINUE FROM HERE 📵.  </p>;
    }
    if (read.auth === undefined && read.page !== "DUPLICATE") {
      return (
        <div role="status">
          <svg
            className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="text-sm">Loading...</span>
        </div>
      );
    } else if (read.auth === undefined && read.page === "DUPLICATE") {
      return (
        <div
          href="#"
          className=" flex flex-col m-3 p-6 h-min bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <Lottie
            className="w-52 place-self-center"
            animationData={noentry}
            loop={true}
          />
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {/* You cannot login with 2 devices at the same time. */}
            {locale[read.user.language].button.twoDevices1}
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            {/* Make sure you have closed the application on your other devices and
            refresh this page. */}
            {wsState && console.log('zzzzzz', read.user.language)}
            {locale[read.user.language].button.twoDevices2}
          </p>
        </div>
      );
    } else if (read.auth === false) {
      return (
        <>
          <div
            href="#"
            className="block m-3 p-6 h-min bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <Lottie className=" w-32" animationData={cone} loop={true} />
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {/* Link is expired ⛔ */}
              {locale[read.user.language || "English"].button.linkEXP1} ⛔
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {/* Please call the company if you believe this is a mistake. */}
              {locale[read.user.language || "English"].button.linkEXP2}
            </p>
          </div>
        </>
      );
    } else {
      return <div className="w-9/12 text-4xl">❌❌❌ DISCONNECTED! ❌❌❌</div>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read.auth, read.page, wsState]);

  React.useEffect(() => {
    if (read.tabControl > 0 && read.ws && read.questions[read.currentQuestion - 1]?.question) {
      // console.log('THIS PART RUNS' , 'current' , read.currentQuestion,  read.questions[read.currentQuestion - 1].question)
      read.ws.send(pack({ type: 'TAB_CONTROL', id: read.id, tabControl: read.tabControl, sus: read.questions[read.currentQuestion - 1].question }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read.tabControl]);

  // console.log(logo , `url(${process.env.REACT_APP_DOMAIN}${logo}.jpg)`)

  return (
    <>

      <div className={` flex h-screen justify-center items-center overflow-hidden border-8 border-teal-700 text-txt font-bold relative overflow-y-auto`}  >

        <div className="mx-2 max-w-xs md:w-max md:max-w-2xl md:mt-8 sm:mt-2 md:h-5/6 z-50">
          <RenderIt />
          <Toaster position="top-center" reverseOrder={false} />
        </div>

        {logo && <div className=" bg-repeat absolute w-full h-full"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_DOMAIN}${logo}.jpg)`
            , opacity: 0.1,
            zIndex: -1,
            backgroundSize: '10%',
          }}>
        </div>}

      </div>

      {wsState && <WsListener />}
    </>
  );
}

export default App;
