import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function SpaSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


        <p>
                    La parte de oratoria incluye <span className = 'text-red-600'> {read.user.noq.speaking} </span> Preguntas y tiene 3 minutos para responder a cada una de ellas.
                </p>
                <br />
                <p>
                    Verá el temporizador de las preguntas relacionadas en la parte superior derecha de la aplicación y su cámara se grabe en la parte superior izquierda.La parte de hablar tomará <span className = 'text-red-600'> Un total de {read.user.noq.speaking * 3} minutos para responder todas las preguntas. </span> Tómese su tiempo.
                </p>
                <br />
                <p>
                        Debe hablar al menos <span className = 'text-red-600'> 1 </span> minuto para estar dentro del rango aceptable de nuestra evaluación.
                </p>
                <br />
                <p>
                <span className = 'text-red-600'> No puede leer desde un texto escrito o un discurso memorizado </span>.Necesitamos escuchar su voz y su voz solos sin la ayuda de nadie a su alrededor.
                </p>
                <br />
                <p>
                   Cuando esté listo, debe presionar el botón de registro 🔴 y cuando haya terminado, debe 🟥 detener la grabación.Si no presiona el botón Stop, la aplicación finalizará automáticamente la grabación después de cierto tiempo.
                </p>
                <br />
                <p>
                    Su monitor, movimiento y ojos se están registrando.Recibirá un 0 si abre una solicitud de terceros o engaña en cualquier tipo de formulario.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Si comprende la instrucción, puede presionar, entiendo y comenzar la parte de hablar.
                </h1>

            </div>

        </>
    )



}


export default SpaSpeaking