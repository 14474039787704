import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Bienvenida() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">


            <h1 className=' text-3xl'>
                     bienvenido,
                 </h1>
                 <p>
                     Este examen incluye {read.totalQuestions} preguntas y tiene 60 segundos para responder preguntas de lectura y comprensión auditiva y tiempo variable para preguntas de expresión oral y escrita.
                 </p>
                 <br />
                 <p>
                     Verá el temporizador para las preguntas relacionadas en la parte superior derecha de la aplicación y su cámara web se está grabando en la parte superior izquierda.
                 </p>
                 <br />
                 <p>
                     Debe leer y aceptar nuestros términos de servicio y debe tener una cámara web y un micrófono activos para poder realizar el examen.
                 </p>
                 <br />
                 <p>
                     Si su cámara web no funciona y no puede verse a sí mismo o el micrófono ahora está conectado, el botón de inicio no se activará y no podrá comenzar el examen. Debe asegurarse absolutamente de tener una cámara web y un micrófono que funcionen antes de realizar el examen.
                 </p>
                 <br />
                 <p>
                     Debe asegurarse de que su conexión a Internet esté bien, ya que estamos grabando su voz y su video. Si está desconectado, puede usar el mismo enlace para continuar el examen desde donde lo dejó.
                 </p>
                 <br />
                 <p>
                     No hay puntos negativos para ninguna de las preguntas de opción múltiple, por lo tanto, siéntase libre de responder todas las preguntas tanto como pueda.
                 </p>
                 <br />
                 <h1 className=' text-2xl'>
                    Si entiende las instrucciones, puede presionar Entiendo y comenzar el examen. ¡Buena suerte!
                 </h1>

             </div>
        </>
    )



}


export default Bienvenida