import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function AraWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    يتضمن جزء الكتابة <span className='Text-Red-600'> {read.user.noq.writing} </span> أسئلة ولديك 5 دقائق للإجابة على كل منها.إذا تم الوصول إلى الوقت ولم تقدم إجابتك ، فسيتم الانتقال تلقائيًا إلى السؤال التالي.
                </p>
                <br />
                <p>
                    سترى الموقت للأسئلة ذات الصلة في أعلى يمين التطبيق ويتم تسجيل الكاميرا في أعلى اليسار.سيستغرق جزء الكتابة <span className='Text-Red-600'> إجمالي {read.user.noq.writing * 5} دقيقة للإجابة على جميع الأسئلة. </span> يرجى أخذ وقتك.
                </p>
                <br />
                <p>
                    يجب أن تكتب على الأقل <span className='Text-Red-600'> 80 </span> الكلمات لتكون ضمن النطاق المقبول لتقييمنا.لا يجوز لك نسخ أو لصق أي مادة ، كبيرة أو صغيرة من أي مصادر أخرى.
                </p>
                <br />
                <p>
                    إذا كنت غير قادر على الكتابة ، فيمكنك تخطي السؤال وفقدان النقطة.
                </p>
                <br />
                <p>
                    يتم تسجيل شاشتك وحركتك وعينك.سوف تتلقى 0 إذا فتحت طلبًا من طرف ثالث أو غش في أي نوع من النماذج.
                </p>
                <br />
                <h1 className='text-2xl'>
                    إذا فهمت التعليمات ، فيمكنك الضغط على جزء من الكتابة والبدء في الكتابة.
                </h1>

            </div>

        </>
    )



}


export default AraWriting