import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Willkommen() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">

            <h1 className='text-3xl'>
                     Willkommen,
                 </h1>
                 <p>
                     Diese Prüfung umfasst {read.totalQuestions} Fragen und Sie haben 60 Sekunden Zeit, um Fragen zum Lesen und Hören zu beantworten, und variable Zeit, um Fragen zu sprechen und zu schreiben.
                 </p>
                 <br />
                 <p>
                     Sie sehen den Timer für die entsprechenden Fragen oben rechts in der Anwendung und Ihre Webcam, die oben links aufgezeichnet wird.
                 </p>
                 <br />
                 <p>
                     Sie müssen unsere Nutzungsbedingungen lesen und ihnen zustimmen und Sie müssen über eine aktive Webcam und ein aktives Mikrofon verfügen, um die Prüfung ablegen zu können.
                 </p>
                 <br />
                 <p>
                     Wenn Ihre Webcam nicht funktioniert und Sie sich nicht sehen können oder das Mikrofon jetzt verbunden ist, wird der Start-Button nicht aktiviert und Sie können die Prüfung nicht starten. Stellen Sie vor der Prüfung unbedingt sicher, dass Sie über eine funktionierende Webcam und ein funktionierendes Mikrofon verfügen.
                 </p>
                 <br />
                 <p>
                     Sie müssen sicherstellen, dass Ihre Internetverbindung in Ordnung ist, da wir Ihre Stimme und Ihr Video aufzeichnen. Wenn die Verbindung unterbrochen wird, können Sie denselben Link verwenden, um die Prüfung an der Stelle fortzusetzen, an der Sie aufgehört haben.
                 </p>
                 <br />
                 <p>
                     Für keine der Multiple-Choice-Fragen gibt es einen negativen Punkt, daher können Sie alle Fragen so gut wie möglich beantworten.
                 </p>
                 <br />
                 <h1 className='text-2xl'>
                    Wenn Sie die Anweisung verstanden haben, können Sie auf Ich verstehe drücken und mit der Prüfung beginnen. Viel Glück!
                 </h1>

             </div>
        </>
    )



}


export default Willkommen