import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function DutReading() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">

                <p>
                    Het leesgedeelte omvat <span className='text-red-600'> {read.user.noq.reading} </span> Vragen en u hebt 60 seconden om elk van hen te beantwoorden.De vragen zullen niet herhalen en als u geen tijd meer hebt, gaat de applicatie automatisch naar de volgende vraag.
                </p>
                <br />
                <p>
                    U ziet de timer voor de gerelateerde vragen rechtsboven op de applicatie en uw camera linksboven opgenomen.Het leesgedeelte neemt <span className='text-red-600'> een totaal van {read.user.noq.reading} minuten om alle vragen te beantwoorden. </span> Neem uw tijd.
                </p>
                <br />
                <p>
                    Er is geen negatief punt voor een van de meerkeuzevragen, daarom beantwoord je alle vragen zoveel mogelijk.
                </p>
                <br />
                <p>
                    Uw monitor, beweging en ogen worden opgenomen.U ontvangt een 0 als u een aanvraag van derden opent of vals speelt in enig formulier.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Als u de instructie begrijpt, kunt u drukken op het leesgedeelte.
                </h1>

            </div>

        </>
    )



}


export default DutReading