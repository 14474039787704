import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function RusReading() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Часть чтения включает в себя <span className='text-red-600'> {read.user.noq.reading} </span> вопросы, и у вас есть 60 секунд, чтобы ответить на каждый из них.Вопросы не будут повторяться, и если у вас не хватит времени, приложение автоматически перейдет к следующему вопросу.
                </p>
                <br />
                <p>
                    Вы увидите таймер для связанных вопросов в правом верхнем углу приложения, а камера записана в верхней части левого.Часть чтения займет <span className='text-red-600'> В общей сложности {read.user.noq.reading} минут, чтобы ответить на все вопросы. </span> Пожалуйста, не торопитесь.
                </p>
                <br />
                <p>
                    Нет негативного пункта для любого из вопросов с множественным выбором, поэтому не стесняйтесь отвечать на все вопросы как можно больше.
                </p>
                <br />
                <p>
                    Ваш монитор, движение и глаза записываются.Вы получите 0, если откроете третье лицо или обманывают в какой -либо форме.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Если вы понимаете инструкцию, вы можете нажать, я понимаю, и начать деталь для чтения.
                </h1>

            </div>

        </>
    )



}


export default RusReading