import '../App.css';
import React from 'react';
import { write, useSnap } from '../store'
import Lottie from "lottie-react";
// import toast from 'react-hot-toast';
// import { ClipboardEvent } from "react"
// import { WsContext } from '../App'
import { pack } from 'msgpackr';
import micstop from '../assets/micstop.json'
import recording2 from '../assets/recording2.json'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { locale } from "../components/locale";


let first = 0

// let recorder

function Speaking() {

    const read = useSnap()
    const ws = read.ws
    const clickRef = React.useRef(0) // this makes sure the button is clicked only once.
    const [timeIt, setTimeIt] = React.useState(0);
    const lottieRef = React.useRef()
    const [recording, setRecording] = React.useState(false);



    React.useEffect(() => {
        write.stopTimer = true
        lottieRef.current.stop()
    }, []);


    React.useEffect(() => { // general recording stops here.
        if (read.recorder.state === 'recording' && first < 1) {
            read.recorder.stop()
            first++
            ws.send(pack({ type: 'REC_FINISHED', format: 'ALL' }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [read.recorder]);




    const timeMe = React.useCallback(() => {

        setInterval(() => {
            setTimeIt(draft => draft + 1)
        }, 1000);

    }, [])



    const Pre = React.useCallback(() => {


        if (read.mR) {
            return (
                <div className='flex flex-col items-center'>
                    <div>
                        <CountdownCircleTimer
                            size={80}
                            isPlaying={!recording}
                            duration={45}
                            colors={['#99C24D', '#FFFF00', '#F18F01', '#A30000']}
                            colorsTime={[45, 30, 20, 0]}
                            onComplete={() => {
                                handleClick()
                            }}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>
                    <div>
                        <p>{locale[read.user.language].button.speakingTimer}</p>
                    </div>
                </div>
            )
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [read.mR])



    function handleClick() {
        if (lottieRef.current.animationItem.currentFrame > 60 && clickRef.current === 1) {
            clickRef.current++
            read.recorder.stop()
            write.loading = true
            ws.send(pack({ type: 'ONE_ANSWER', id: read.id, data: `${read.id}-speaking-${read.questions[read.currentQuestion - 1].qID}.mp4`, index: read.currentQuestion - 1 }))
            // console.log(`${read.id}-speaking-${null}.mp4` , read.questions[read.currentQuestion - 1].qID , read.questions[read.currentQuestion - 1].questionType)
            setRecording(false)
            write.stopTimer = true
            ws.send(pack({ type: 'REC_FINISHED', format: read.questions[read.currentQuestion - 1].qID })) // format is the -filename when saved.
        } else if (lottieRef.current.animationItem.currentFrame < 60 && clickRef.current === 0) {
            clickRef.current++
            read.mR()
            timeMe()
            write.stopTimer = false
            lottieRef.current.play()
            setRecording(true)
        }
    }




    return (
        <>
            <div className='self-center text-center'>
                {
                    recording && <div className='flex flex-col items-center justify-center'> <Lottie className={` w-24 self-center`} animationData={recording2} loop={true} />
                        <p className=' text-sm text-teal-600' onClick={timeMe}> {timeIt}  / 90 secs</p>
                    </div>
                }
                <Lottie onClick={handleClick} lottieRef={lottieRef} className=' w-32 cursor-pointer md:mb-20 hover:scale-125 transform-gpu' animationData={micstop} loop={false} />
            </div>

            <div className=' text-center flex items-center justify-center content-center'>
                {recording ? <div className='self-center'> {locale[read.user.language].button.stopRec}</div> : <Pre />}
            </div>
        </>
    );
}

export default Speaking;