import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function DutListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Het luisterdeel omvat <span className='text-red-600'> {read.user.noq.listening} </span> Vragen en u hebt 60 seconden om elk van hen te beantwoorden.De vragen zullen niet herhalen en als u geen tijd meer hebt, gaat de applicatie automatisch naar de volgende vraag.
                </p>
                <br />
                <p>
                    U ziet de timer voor de gerelateerde vragen rechtsboven op de applicatie en uw camera linksboven opgenomen.Het luisterdeel neemt <span className='text-red-600'> een totaal van {read.user.noq.listening} minuten om alle vragen te beantwoorden. </span> Neem uw tijd.
                </p>
                <br />
                <p>
                    U ziet een afspeelknop.Druk op de afspeelknop wanneer u klaar bent om te luisteren.
                </p>
                <br />
                <p>
                    De timer begint wanneer je klaar bent met het luisteren naar de audio.De audio wordt maar één keer gespeeld en je kunt hem niet meer horen.Vanaf daar heb je tijd om een van de antwoorden te kiezen.
                </p>
                <br />
                <p>
                    Er is geen negatief punt voor een van de meerkeuzevragen, daarom beantwoord je alle vragen zoveel mogelijk.
                </p>
                <br />
                <p>
                    Uw monitor, beweging en ogen worden opgenomen.U ontvangt een 0 als u een aanvraag van derden opent of vals speelt in enig formulier.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Als u de instructie begrijpt, kunt u drukken, begrijp ik en start het luistergedeelte.
                </h1>

            </div>

        </>
    )



}


export default DutListening