import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Bienvenida() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col">


            <h1 className=' text-3xl'>
                     welkom,
                 </h1>
                 <p>
                     Dit examen bevat {read.totalQuestions} vragen en je hebt 60 seconden om lees- en luistervragen te beantwoorden en variabele tijd voor spreek- en schrijfvragen.
                 </p>
                 <br />
                 <p>
                     Rechtsboven in de applicatie ziet u de timer voor de gerelateerde vragen en linksboven wordt uw webcam opgenomen.
                 </p>
                 <br />
                 <p>
                     U moet onze servicevoorwaarden lezen en ermee akkoord gaan en u moet een actieve webcam en microfoon hebben om het examen af te leggen.
                 </p>
                 <br />
                 <p>
                     Als je webcam niet werkt en je jezelf niet kunt zien of de microfoon is nu aangesloten, dan wordt de startknop niet geactiveerd en kun je het examen niet starten. U moet er absoluut voor zorgen dat u een werkende webcam en microfoon heeft voordat u het examen aflegt.
                 </p>
                 <br />
                 <p>
                     U moet ervoor zorgen dat uw internetverbinding in orde is, aangezien we uw stem en uw video opnemen. Als de verbinding is verbroken, kunt u dezelfde link gebruiken om verder te gaan met het examen waar u was gebleven.
                 </p>
                 <br />
                 <p>
                     Er is geen negatief punt voor een van de meerkeuzevragen, dus voel je vrij om alle vragen zoveel mogelijk te beantwoorden.
                 </p>
                 <br />
                 <h1 className=' text-2xl'>
                    Als u de instructie begrijpt, kunt u op Ik begrijp het drukken en het examen starten. Succes!
                 </h1>

             </div>
        </>
    )



}


export default Bienvenida