import React from 'react';
// import { useSnap } from '../../store'
// import toast from 'react-hot-toast';


function Turkishtos1() {
    // const read = useSnap()


return(

<>

<div className="flex flex-col max-w-fit">

  <p className="MsoNormal" align="center" style={{marginBottom: '0in', textAlign: 'center', lineHeight: 'normal', background: 'white'}}><b><span lang="TR" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: '#818181'}}>Kişisel Verilerin Korunması
        Kanunu Çerçevesinde</span></b></p>
  <p className="MsoNormal" align="center" style={{marginBottom: '0in', textAlign: 'center', lineHeight: 'normal', background: 'white'}}><b><span lang="TR" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: '#818181'}}>Global Assessment Eğitim ve
        Danışmanlık Tic. Ltd. Şti.&nbsp; Eğitim Alan&nbsp;Açık Rıza Metni</span></b></p>
  <p className="MsoNormal" align="center" style={{marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt'}}><b><span lang="TR" style={{fontSize: '10.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></b></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>6698 sayılı
      Kişisel Verilerin Korunması Kanunu gereğince, tarafımın kişisel verilerimin
      Global Assessment Eğitim ve Danışmanlık Tic. Ltd. Şti.&nbsp; (Global Assessment)
      tarafından, hangi amaçla işleneceği, işlenen kişisel verilerin kimlere ve hangi
      amaçla aktarılabileceği, kişisel veri toplamanın yöntemi ve hukuki sebebi ve
      Kanun’un 11. maddesinde sayılan diğer haklarım hususlarında bilgilendirildim.
      Bu kapsamda tarafıma sunulan Aydınlatma Metnini okumuş ve anlamış
      bulunmaktayım.</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Global
      İngilizce Eğitim ve Danışmanlık Tic. Ltd. Şti. tarafından;</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Şirketimiz tarafından yapılan yabancı dil mülakat hizmetinin mülakat yapan
      adayların görsel video, ses ve ekran görüntü kaydının online olarak yapılması
      ve mülakatı talep eden şirket, yönetici ve ilgili şirket personeli ile
      paylaşımı,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Mülakat yapılacak adayların kayıtları oluşturulması ve yönetilmesi,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Adaylar ile ilgili olarak yasal sınırlar içerisinde değerlendirme
      çalışmalarının yapılması,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Hizmet
      şartlarımızda ve elektronik hizmetlerimizde meydana gelebilecek değişiklikler
      hakkında bilgilendirme yapılması,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Şirketimiz
      ve iş ilişkisi içerisinde olduğu kişilerin hukuki ve ticari güvenliğinin
      temini,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Adayların
      şikayetlerinin çözülmesi ve veri erişim veya düzeltme taleplerinin işleme
      alınması,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Bilgi
      güvenliği süreçlerinin planlanması, bilgi teknolojileri alt yapısının
      oluşturulması ve yönetilmesi,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Yasal
      hakları belirlemek ve bunları savunmak; faaliyetlerimizi veya iş ortaklarımızın
      faaliyetlerini, haklarımızı, gizliliğimizi, güvenliğimizi veya malımızı ve/veya
      sizin veya başkalarının bu sayılan varlıklarının korunması ve kullanılabilir
      çarelerin uygulanması veya zararımızın sınırlandırılması gibi</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      İş ortakları ve tedarikçilerle olan ilişkilerin yönetimi,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Şirketimiz
      tarafından sunulan program ve eğitimlerin planlanması ve yönetilmesi ilgili
      mevzuat kapsamındaki kanuni yükümlülüklerin yerine getirilmesi,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Şirketimizin
      anlaşmalı olduğu servis hizmetinin verilmesi ile ilgili gerekli kayıtların
      oluşturulması, ilgili yerlere bilgilerin aktarılması</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Şirketimizin kalite, bilgi güvenliği ve gizlilik politikalarının ve
      standartlarının sağlıklı olarak </span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Yasal
      düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde kamu kurum ve
      kuruluşlarının taleplerinin yerine getirilmesi,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Kanunda belirtilmiş yasal yükümlülüklerin yerine getirilmesi amacıyla</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>İşlenmesine
      rıza göstermekte, kuruma ilettiğim tüm bu verilerimin kurum tarafından
      kaydedilmesine, depolanmasına, değiştirilmesine, güncellenmesine, periyodik
      olarak kontrol edilmesine, yeniden düzenlenmesine, sınıflandırılmasına ve
      yukarıda bahsi geçen kişisel verilerimin kurumun insan kaynakları havuzunda
      muhafaza edilmesine, yukarıdaki amaçlar için işbirliğinde bulunulan üçüncü kişi
      olan iş ortakları, hizmet sağlayıcıları (arşiv şirketleri, bilişim hizmet
      sağlayıcıları dahil olmak üzere) ile paylaşılmasına,</span></p>
  <p className="MsoNormal" style={{marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in'}}><span lang="TR" style={{fontSize: '14.0pt', fontFamily: 'Symbol', color: 'black'}}></span><span lang="TR" style={{fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>Onay Veriyorum.</span></p>

  <p className="MsoNormal" align="center" style={{marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span><b><span lang="TR" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: '#818181'}}>Within the framework of the Personal
        Data Protection Law</span></b></p>
  <p className="MsoNormal" align="center" style={{marginBottom: '3.0pt', textAlign: 'center'}}><b><span lang="TR" style={{fontSize: '14.0pt', fontFamily: '"Times New Roman",serif', color: '#818181'}}>Global
        English Education and Consulting Trade. Ltd. Sti. Open Consent Text for
        Education</span></b></p>
  <p className="MsoNormal" align="center" style={{marginBottom: '3.0pt', textAlign: 'center', lineHeight: '12.85pt'}}><b><span lang="TR" style={{fontSize: '10.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></b></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>In accordance
      with the Law on Protection of Personal Data No. 6698, my personal data is
      registered under Global Assessment Education and Consultancy Tic. Ltd. Sti. I was
      informed by (Global Assessment) about the purpose for which it will be processed,
      to whom and for what purpose the processed personal data can be transferred,
      the method and legal reason for collecting personal data, and my other rights
      listed in Article 11 of the Law. In this context, I have read and understood
      the Clarification Text presented to me.</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>By
      Global Assessment Education and Consulting Trade. Ltd.</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Making online video, audio and screenshot recording of the foreign language
      interview service provided by our company, and sharing it with the company,
      manager and relevant company personnel requesting the interview,</span></p>
  <p className="MsoNormal" style={{marginLeft: '35.45pt', textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Mülakat yapılacak adayların kayıtları oluşturulması ve yönetilmesi,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Evaluation
      of candidates within legal limits,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Informing about changes that may occur in our terms of service and electronic
      services,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Ensuring the legal and commercial security of our company and the people with
      whom it has business relations,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Resolving
      candidates' complaints and processing data access or correction requests,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Planning information security processes, creating and managing information
      technology infrastructure,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Identifying and defending legal rights; such as protecting our activities or
      the activities of our business partners, our rights, privacy, security or
      property and/or such assets of you or others, and applying available remedies
      or limiting our damage.</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Management of relations with business partners and suppliers,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Planning and managing the programs and trainings offered by our company,
      fulfilling legal obligations under the relevant legislation,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-Creating
      the necessary records regarding the delivery of the contracted service of our
      company, transferring the information to the relevant places</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      The soundness of our company's quality, information security and privacy
      policies and standards</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '35.4pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      Fulfilling the demands of public institutions and organizations as required or
      mandated by legal regulations,</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', textIndent: '.25in', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>-
      In order to fulfill the legal obligations specified in the law</span></p>
  <p className="MsoNormal" style={{marginLeft: '.5in', textAlign: 'justify', textIndent: '-.6pt', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>I consent to the processing of all these data that I have
      forwarded to the institution, to the institution to record, store, change,
      update, periodically control, reorganize, classify and keep my above-mentioned
      personal data in the human resources pool of the institution, partners, service
      providers (including archive companies, IT service providers),</span></p>
  <p className="MsoNormal" style={{marginLeft: '.5in', textAlign: 'justify', textIndent: '-.25in'}}><span lang="TR" style={{fontSize: '14.0pt', fontFamily: 'Symbol', color: 'black'}}></span><span lang="TR" style={{fontSize: '7.0pt', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>I approve.</span></p>

  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{color: 'black'}}>&nbsp;</span></p>
  <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{color: 'black'}}>&nbsp;</span></p>
  <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '11.75pt'}}><span lang="TR" style={{color: 'black'}}>&nbsp;</span></p>
  <p className="MsoNormal"><span lang="TR">&nbsp;</span></p>
</div>




</>
)



}


export default Turkishtos1