import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function Bienvenida() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <h1 className=' text-3xl'>
                    환영,
                </h1>
                <p>
                    이 시험에는 {read.totalQuestions}개의 문항이 포함되어 있으며 60초 동안 읽기 및 듣기 질문에 답하고 말하기 및 쓰기 질문에는 변수 시간이 주어집니다.
                </p>
                <br />
                <p>
                    응용 프로그램의 오른쪽 상단에 관련 질문에 대한 타이머가 표시되고 왼쪽 상단에 웹캠이 녹화되는 것을 볼 수 있습니다.
                </p>
                <br />
                <p>
                    서비스 약관을 읽고 동의해야 하며 시험에 응시하려면 활성 웹캠과 마이크가 있어야 합니다.
                </p>
                <br />
                <p>
                    웹캠이 작동하지 않아 자신을 볼 수 없거나 마이크가 연결된 경우 시작 버튼이 활성화되지 않고 시험을 시작할 수 없습니다. 시험을 치르기 전에 작동하는 웹캠과 마이크가 있는지 반드시 확인해야 합니다.
                </p>
                <br />
                <p>
                    귀하의 음성과 비디오를 녹음하고 있으므로 인터넷 연결이 정상인지 확인해야 합니다. 연결이 끊어진 경우 동일한 링크를 사용하여 중단한 부분부터 시험을 계속할 수 있습니다.
                </p>
                <br />
                <p>
                    객관식 질문에는 감점이 없으므로 가능한 한 모든 질문에 자유롭게 답하십시오.
                </p>
                <br />
                <h1 className=' text-2xl'>
                    지시 사항을 이해했다면 이해했습니다를 누르고 시험을 시작할 수 있습니다. 행운을 빌어요!
                </h1>

            </div>
        </>
    )



}


export default Bienvenida