import '../App.css';
import React from 'react';
import { write, useSnap } from '../store'
// import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Listening from '../components/Listening'
import Writing from '../components/writing'
import Speaking from '../components/speaking'
import LoadingOverCard from './loadingOverCard'
// import { WsContext } from '../App'
import { pack } from 'msgpackr';
// import toast from 'react-hot-toast';
import CD from '../components/cd'
import NewModal from '../components/newModal';


const choices = ['A.', 'B.', 'C.', 'D.', 'E.', 'F.']

function SingleQ({ questionData, type }) {
    const contentRef = React.useRef()
    const read = useSnap()
    const [selected, setSelected] = React.useState();
    // const [fontSize, setFontSize] = React.useState();
    // const ws = React.useContext(WsContext);
    const ws = read.ws

    const handleAnswer = (e) => {
        if (!selected) {
            setSelected(e.target.value)
            write.stopTimer = true
            // write.answers.push(e.target.value) // we dont save the answers locally anymore
            setTimeout(() => {
                ws.send(pack({ type: 'ONE_ANSWER', id : read.id , data: e.target.value , index : read.currentQuestion - 1 }))
                write.loading = true
            }, 600);
        }
    }



    const RenderQuestions = React.useCallback(() => {
        return (
            <form onChange={handleAnswer}>
                <ul className="grid gap-6 w-full md:grid-cols-2">

                    {
                        questionData.answers.map((answer, index) => { // speaking and writing don't have [] of answers.
                            return (
                                <li className='relative' key={index}>
                                    <input disabled={selected ? true : false} type="radio" id={index} name="answers" value={index + 1} className="hidden peer" required />
                                    <label htmlFor={index} className="inline-flex justify-center items-center p-3 w-full text-gray-500 bg-white rounded-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-green-500 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div className="block">
                                            <div style={{ overflowWrap: 'anywhere' }} className="w-full max-h-28 md:max-h-60 overflow-auto">{answer}</div>
                                        </div>
                                        {selected - 1 === index && <>✅</>}
                                    </label>
                                    <div className='absolute top-0 left-0 text-gray-400 text-xs ml-1 mt-1'> {choices[index]} </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </form>
        )


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log(read.tabControl)



    return (

        <div className=' w-auto'>
            <div className='absolute top-0 right-0 p-2 text-4xl'>
        
              <div className=' text-green-500 text-lg'>  <CD />  </div>
            </div>

            <div className="relative max-w-sm min-w-fit md:w-96 md:max-w-4xl">
                {read.loading && <LoadingOverCard />}
                {/* {console.log(read.instruction[type] , read.instruction , type , read.currentQuestionType)} */}
                {read.instruction[type] && <div ref={contentRef} className='flex flex-col rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800  max-h-500 py-3 px-1' >
                    {questionData.questionType === 'listening' && <Listening dataPath={questionData.dataPath} />}
                    <p className={`text-2xl max-h-40 overflow-y-auto font-bold tracking-tight text-gray-900 dark:text-white text-justify md:text-center p-2`}>
                        {questionData.question}
                    </p>
                    {questionData.questionType === 'writing' && <Writing />}
                    {questionData.questionType === 'speaking' && <Speaking />}
                    {questionData.answers.length !== 0 && <RenderQuestions />}
                    { read.tabControl === 1 && <NewModal type={'tabCTRL'} />}
                </div>}
            </div>
        </div>


    );
}

export default SingleQ;










