import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function TurReading() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Okuma kısmı <span className='text-red-600'> {read.user.noq.reading} </span> soruları içerir ve her birini cevaplamak için 60 saniyeniz vardır.Sorular tekrarlamaz ve zamanınız tükenirse, uygulama otomatik olarak bir sonraki soruya geçer.
                </p>
                <br />
                <p>
                    Uygulamanın sağ üst kısmındaki ilgili soruların zamanlayıcıyı ve kameranızın sol üstte kaydedildiğini göreceksiniz.Okuma kısmı <span className='Text-Red-600'> Tüm soruları cevaplamak için toplam {read.user.noq.reading} dakika alacaktır. </span> Lütfen zaman ayırın.
                </p>
                <br />
                <p>
                    Çoktan seçmeli soruların hiçbiri için olumsuz bir anlam yoktur, bu nedenle tüm soruları olabildiğince cevaplamaktan çekinmeyin.
                </p>
                <br />
                <p>
                    Monitörünüz, hareketiniz ve gözleriniz kaydediliyor.Üçüncü taraf bir başvuruyu açarsanız veya herhangi bir şekilde hile yaparsanız 0 alırsınız.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Talimatı anlarsanız, anladığım ve okuma bölümünü başlatabilirim.
                </h1>
            </div>

        </>
    )



}


export default TurReading