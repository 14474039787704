import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function ItaSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    La parte parlante include <span className='text-red-600'> {read.user.noq.speaking} </span> domande e hai 3 minuti per rispondere a ciascuno di essi.
                </p>
                <br />
                <p>
                    Vedrai il timer per le domande correlate in alto a destra dell'applicazione e la fotocamera registrata in alto a sinistra.La parte parlante prenderà <span className='text-red-600'> Un totale di {read.user.noq.speaking * 3} minuti per rispondere a tutte le domande. </span> Per favore prenditi il tuo tempo.
                </p>
                <br />
                <p>
                    Devi parlare almeno <span className='text-red-600'> 1 </span> minuto per rientrare nell'intervallo accettabile della nostra valutazione.
                </p>
                <br />
                <p>
                    <span className='text-red-600'> Non è possibile leggere da un testo scritto o un discorso memorizzato </span>.Dobbiamo ascoltare la tua voce e la tua voce da soli senza l'aiuto di nessuno intorno a te.
                </p>
                <br />
                <p>
                    Quando sei pronto, è necessario premere il pulsante 🔴 Registra e quando hai finito, dovresti 🟥 Fermare la registrazione.Se non si preme il pulsante STOP, l'applicazione terminerà automaticamente la registrazione dopo un determinato tempo.
                </p>
                <br />
                <p>
                    Il monitor, il movimento e gli occhi vengono registrati.Riceverai uno 0 se apri un'applicazione di terze parti o imbrogliate in qualsiasi tipo di forma.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Se capisci le istruzioni, puoi premere, capisco e avvia la parte di lingua.
                </h1>

            </div>

        </>
    )



}


export default ItaSpeaking