import '../App.css';
import React from 'react';
import { write, useSnap } from '../store'
// import Lottie from "lottie-react";
import toast from 'react-hot-toast';
// import { ClipboardEvent } from "react"
// import { WsContext } from '../App'
import { pack } from 'msgpackr';
import { locale } from './locale';


function Writing({ dataPath }) {
    const read = useSnap()
    // const [allWords, setAllWords] = React.useState('out');
    const [wordControl, setWordControl] = React.useState(0);
    const ws = read.ws


    React.useEffect(() => {
        write.writingAllWords = ''
        if (read.instruction[read.questions[read.currentQuestion - 1].questionType]) {
            write.stopTimer = false
        }
    }, [read.currentQuestion, read.instruction, read.questions]);


    // console.log(read.instruction[read.questions[read.currentQuestion - 1].questionType], !read.stopTimer, '🍀🍀🍀')

    React.useEffect((e) => {
        wordControl <= 80 && setWordControl(read.writingAllWords.toString().split(" ").length - 1);
    }, [read.writingAllWords, wordControl])

    const preventCopyPaste = (e) => {
        e.preventDefault()
        toast.error('Pasting is not allowed on this platform. Please use your own knowledge to write the text. ❌', { duration: 3333 })
    }

    function handleSend() {
        setTimeout(() => {
            ws.send(pack({ type: 'ONE_ANSWER', id : read.id , data: read.writingAllWords, index: read.currentQuestion - 1 })) 
            write.loading = true
        }, 999);
    }



    // console.log('WRITINGNGG ' , read.writingAllWords)

    return (
        <>
            <div className='flex flex-col items-center'>
                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    {locale[read.user.language].button.wordCount} : <span className={wordControl < 80 ? 'text-red-600' : 'text-green-500'}>{wordControl}</span></label>
                <textarea spellCheck={false} onChange={(e) => write.writingAllWords = e.currentTarget.value} onPaste={preventCopyPaste} id="message" rows={10} className="block resize-none h-auto w-full p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-2 dark:text-white md:w-96 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your message..." defaultValue={""} />
                { // should be >= with wordControl
                    wordControl >= 80 ? <button onClick={handleSend} type="button" className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{locale[read.user.language].button.send}</button> : <button disabled type="button" className=" py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 cursor-not-allowed  dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">{80 - wordControl} {locale[read.user.language].button.moreWords}</button>
                }
            </div>
            <pre className=' w-fit cursor-pointer hidden' onClick={handleSend}>{locale[read.user.language].button.skip}</pre>
        </>
    );
}

export default Writing;