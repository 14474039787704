import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function RusListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


            <p>
                    Часть прослушивания включает <span className = 'text-red-600'> {read.user.noq.listening} </span> вопросы, и у вас есть 60 секунд, чтобы ответить на каждый из них.Вопросы не будут повторяться, и если у вас не хватит времени, приложение автоматически перейдет к следующему вопросу.
                </p>
                <br />
                <p>
                    Вы увидите таймер для связанных вопросов в правом верхнем углу приложения, а камера записана в верхней части левого.Часть прослушивания займет <span className = 'text-red-600'> В общей сложности {read.user.noq.listening} минут, чтобы ответить на все вопросы. </span> Пожалуйста, не торопитесь.
                </p>
                <br />
                <p>
                        Вы увидите кнопку воспроизведения.Нажмите кнопку воспроизведения, когда вы будете готовы к прослушиванию.
                </p>
                <br />
                <p>
                        Таймер начинается, когда вы закончили слушать аудио.Аудио будет сыгран только один раз, и вы не сможете услышать его снова.Оттуда у вас есть время выбрать один из ответов.
                </p>
                <br />
                <p>
                    Нет негативного пункта для любого из вопросов с множественным выбором, поэтому не стесняйтесь отвечать на все вопросы как можно больше.
                </p>
                <br />
                <p>
                    Ваш монитор, движение и глаза записываются.Вы получите 0, если откроете третье лицо или обманывают в какой -либо форме.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Если вы понимаете инструкцию, вы можете нажать, я понимаю, и запустить часть прослушивания.
                </h1>

            </div>

        </>
    )



}


export default RusListening