import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function FreSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    La partie parlante comprend <span className='text-red-600'> {read.user.noq.speaking} </span> et vous avez 3 minutes pour répondre à chacun d'eux.
                </p>
                <br />
                <p>
                    Vous verrez la minuterie pour les questions connexes en haut à droite de l'application et votre appareil photo enregistré en haut à gauche.La partie parlante prendra <span className='text-red-600'> un total de {read.user.noq.speaking * 3} minutes pour répondre à toutes les questions. </span> Veuillez prendre votre temps.
                </p>
                <br />
                <p>
                    Vous devez parler au moins <span className='text-red-600'> 1 </span> minute pour être dans la plage acceptable de notre évaluation.
                </p>
                <br />
                <p>
                    <span className='text-red-600'> Vous ne pouvez pas lire à partir d'un texte écrit ou d'un discours mémorisé </span>.Nous devons entendre votre voix et votre voix seule sans l'aide de quiconque autour de vous.
                </p>
                <br />
                <p>
                    Lorsque vous êtes prêt, vous devez appuyer sur le bouton d'enregistrement 🔴 et lorsque vous avez terminé, vous devez 🟥 arrêter l'enregistrement.Si vous n'appuyez pas sur le bouton d'arrêt, l'application terminera automatiquement l'enregistrement après certains temps.
                </p>
                <br />
                <p>
                    Votre moniteur, vos mouvements et vos yeux sont enregistrés.Vous recevrez un 0 si vous ouvrez une application tierce ou triche dans une sorte de formulaire.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Si vous comprenez l'instruction, vous pouvez appuyer sur Je comprends et commence la partie parlant.
                </h1>

            </div>

        </>
    )



}


export default FreSpeaking