import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function ItaReading() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Questo esame ha <span className='text-red-600'> {read.user.noq.reading} </span>  Domande e hai 60 secondi per rispondere a domande di lettura , ascolto e tempo per produzione orale e scritta.
                </p>
                <br />
                <p>
                    Vedrai il timer per le domande correlate in alto a destra dell'applicazione e la fotocamera registrata in alto a sinistra.La parte di lettura prenderà <span className='text-red-600'> Un totale di {read.user.noq.reading} minuti per rispondere a tutte le domande. </span> Per favore prenditi il tuo tempo.
                </p>
                <br />
                <p>
                    Non esiste un punto negativo per nessuna delle domande a scelta multipla, quindi sentiti libero di rispondere a tutte le domande il più possibile.
                </p>
                <br />
                <p>
                    Il monitor, il movimento e gli occhi vengono registrati.Riceverai uno 0 se apri un'applicazione di terze parti o imbrogliate in qualsiasi tipo di forma.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Se capisci le istruzioni, puoi premere,
                    capire e avviare l'esame.
                    Buona fortuna!
                </h1>

            </div>

        </>
    )



}


export default ItaReading