import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function SpaListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    La parte de escucha incluye <span className='text-red-600'> {read.user.noq.listening} </span> Preguntas y tiene 60 segundos para responder a cada una de ellas.Las preguntas no se repitirán y si se queda sin tiempo, la aplicación se moverá automáticamente a la siguiente pregunta.
                </p>
                <br />
                <p>
                    Verá el temporizador de las preguntas relacionadas en la parte superior derecha de la aplicación y su cámara se grabe en la parte superior izquierda.La parte de escucha tomará <span className='text-red-600'> Un total de {read.user.noq.listening} minutos para responder todas las preguntas. </span> Tómese su tiempo.
                </p>
                <br />
                <p>
                    Verás un botón de reproducción.Presione el botón Reproducir cuando esté listo para escuchar.
                </p>
                <br />
                <p>
                    El temporizador comienza cuando terminaste de escuchar el audio.El audio se reproducirá solo una vez y no podrás volver a escucharlo.Desde allí, tienes tiempo para elegir una de las respuestas.
                </p>
                <br />
                <p>
                    No hay un punto negativo para ninguna de las preguntas de opción múltiple, por lo tanto, no dude en responder todas las preguntas tanto como pueda.
                </p>
                <br />
                <p>
                    Su monitor, movimiento y ojos se están registrando.Recibirá un 0 si abre una solicitud de terceros o engaña en cualquier tipo de formulario.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Si comprende la instrucción, puede presionar, entiendo y comenzar la parte de escucha.
                </h1>

            </div>

        </>
    )



}


export default SpaListening