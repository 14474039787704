import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function FreReading() {
    const read = useSnap()


    return (

        <>

          <div className = "flex flex-col text-justify">


                <p>
                    La partie de lecture comprend <span className = 'text-red-600'> {read.user.noq.reading} </span> et vous avez 60 secondes pour répondre à chacun d'eux.Les questions ne se répéteront pas et si vous manquez de temps, l'application passera automatiquement à la question suivante.
                </p>
                <br />
                <p>
                    Vous verrez la minuterie pour les questions connexes en haut à droite de l'application et votre appareil photo enregistré en haut à gauche.La partie de lecture prendra <span className = 'text-red-600'> un total de {read.user.noq.reading} quelques minutes pour répondre à toutes les questions. </span> Veuillez prendre votre temps.
                </p>
                <br />
                <p>
                    Il n'y a aucun point négatif pour aucune des questions à choix multiples, n'hésitez donc pas à répondre à toutes les questions autant que vous le pouvez.
                </p>
                <br />
                <p>
                    Votre moniteur, vos mouvements et vos yeux sont enregistrés.Vous recevrez un 0 si vous ouvrez une application tierce ou triche dans une sorte de formulaire.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Si vous comprenez l'instruction, vous pouvez appuyer sur I Comprendre et commencer la partie de lecture.
                </h1>

            </div>

        </>
    )



}


export default FreReading