import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function FreListening() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    La partie d'écoute comprend <span className='text-red-600'> {read.user.noq.listening} </span> et vous avez 60 secondes pour répondre à chacun d'eux.Les questions ne se répéteront pas et si vous manquez de temps, l'application passera automatiquement à la question suivante.
                </p>
                <br />
                <p>
                    Vous verrez la minuterie pour les questions connexes en haut à droite de l'application et votre appareil photo enregistré en haut à gauche.La partie d'écoute prendra <span className='text-red-600'> un total de {read.user.noq.listening} quelques minutes pour répondre à toutes les questions. </span> Veuillez prendre votre temps.
                </p>
                <br />
                <p>
                    Vous verrez un bouton de lecture.Appuyez sur le bouton de lecture lorsque vous êtes prêt à écouter.
                </p>
                <br />
                <p>
                    La minuterie commence lorsque vous avez fini d'écouter l'audio.L'audio ne sera joué qu'une seule fois et vous ne pourrez plus l'entendre.De là, vous avez le temps de choisir l'une des réponses.
                </p>
                <br />
                <p>
                    Il n'y a aucun point négatif pour aucune des questions à choix multiples, n'hésitez donc pas à répondre à toutes les questions autant que vous le pouvez.
                </p>
                <br />
                <p>
                    Votre moniteur, vos mouvements et vos yeux sont enregistrés.Vous recevrez un 0 si vous ouvrez une application tierce ou triche dans une sorte de formulaire.
                </p>
                <br />
                <h1 className='text-2xl'>
                    Si vous comprenez les instructions, vous pouvez appuyer sur Je comprends et démarrer la partie d'écoute.
                </h1>

            </div>

        </>
    )



}


export default FreListening