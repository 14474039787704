import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function GerWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
               Der Schreibteil enthält <span className= 'text-red-600'> {read.user.noq.writing} </span> Fragen, und Sie haben 5 Minuten Zeit, um jeweils zu beantworten.Wenn die Zeit erreicht ist und Sie Ihre Antwort nicht einreichen, wechselt die Bewerbung automatisch zur nächsten Frage.
                </p>
                <br />
                <p>
                    Sie sehen den Timer für die damit verbundenen Fragen oben rechts in der Anwendung und Ihre Kamera wird oben links aufgezeichnet.Der Schreibteil nimmt <span className = 'text-red-600'> eine Gesamtsumme von {read.user.noq.writing * 5} Minuten, um alle Fragen zu beantworten. </span> Bitte nehmen Sie sich Zeit.
                </p>
                <br />
                <p>
                        Sie müssen mindestens <span className='text-red-600'> 80 </span> Wörter im akzeptablen Bereich unserer Bewertung liegen.Sie dürfen kein Material kopieren oder einfügen, das groß oder klein aus anderen Quellen ist.
                </p>
                <br />
                <p>
                    Wenn Sie nicht schreiben können, können Sie die Frage überspringen und den Punkt verlängern.
                </p>
                <br />
                <p>
                    Ihr Monitor, Ihre Bewegung und Ihre Augen werden aufgezeichnet.Sie erhalten eine 0, wenn Sie einen Drittantrag an Drittanbietern eröffnen oder in irgendeiner Form betrügen.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Wenn Sie die Anweisung verstehen, können Sie den Schreibteil verstehe und starten.
                </h1>
            </div>

        </>
    )



}


export default GerWriting