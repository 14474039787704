import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function DutSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    Het spreekonderdeel omvat <span className = 'text-red-600'> {read.user.noq.speaking} </span> Vragen en u hebt 3 minuten om elk van hen te beantwoorden.
                </p>
                <br />
                <p>
                    U ziet de timer voor de gerelateerde vragen rechtsboven op de applicatie en uw camera linksboven opgenomen.Het sprekende onderdeel neemt <span className = 'text-red-600'> een totaal van {read.user.noq.speaking * 3} minuten om alle vragen te beantwoorden. </span> Neem de tijd.
                </p>
                <br />
                <p>
                        U moet op zijn minst spreken <span className = 'text-red-600'> 1 </span> minuut om binnen het acceptabele bereik van onze beoordeling te zijn.
                </p>
                <br />
                <p>
                <span className = 'text-red-600'> U kunt niet lezen van een schriftelijke tekst of een onthouden speech </span>.We moeten je stem en je stem alleen horen zonder de hulp van iemand om je heen.
                </p>
                <br />
                <p>
                   Als u klaar bent, moet u op de 🔴 -opslagknop drukken en wanneer u klaar bent, moet u 🟥 de opname stoppen.Als u niet op de stopknop drukt, beëindigt de toepassing de opname automatisch na bepaalde tijd.
                </p>
                <br />
                <p>
                    Uw monitor, beweging en ogen worden opgenomen.U ontvangt een 0 als u een aanvraag van derden opent of vals speelt in enig formulier.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Als u de instructie begrijpt, kunt u drukken, begrijp ik het spreekonderdeel.
                </h1>

            </div>

        </>
    )



}


export default DutSpeaking