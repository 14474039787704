import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function KorSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


              <p>
                    말하기 부분에는 <span className = 'text-red-600'> {read.user.noq.speaking} </span> 질문이 포함되며 각각에 답할 수있는 3 분이 있습니다.
                </p>
                <br />
                <p>
                    응용 프로그램 오른쪽 상단에 관련 질문에 대한 타이머와 왼쪽 상단에 카메라가 기록됩니다.말하기 부분은 <span className = 'text-red-600'>를 취합니다. 총 {read.user.noq.speaking * 3}의 총 질문에 답변합니다. </span> 시간을 내주십시오.
                </p>
                <br />
                <p>
                        우리의 평가의 허용 가능한 범위 내에 있으려면 최소 <span className = 'text-Red-600'> 1 </span> 분을 말해야합니다.
                </p>
                <br />
                <p>
                <span className = 'text-red-600'> 서면 텍스트 나 암기 된 연설에서 읽을 수 없습니다 </span>.우리는 주변의 누군가의 도움없이 당신의 목소리와 당신의 목소리를들을 필요가 있습니다.
                </p>
                <br />
                <p>
                   준비가되면 🔴 레코드 버튼을 누르고 완료되면 녹음을 중지해야합니다.정지 버튼을 누르지 않으면 응용 프로그램이 특정 시간 후에 자동으로 녹음을 종료합니다.
                </p>
                <br />
                <p>
                    모니터, 움직임 및 눈이 기록되고 있습니다.타사 신청서를 열거나 어떤 종류의 형태로 치트를 열면 0을 받게됩니다.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    지시를 이해하면 이해하고 말하기 부분을 시작할 수 있습니다.
                </h1>

            </div>

        </>
    )



}


export default KorSpeaking