import { proxy, useSnapshot } from "valtio";


export const write = proxy({
  id: '',
  page: '',
  micAndCamState: false,
  currentQuestion: '',
  totalQuestions: '',
  urlSubstring: '',
  auth: undefined,
  questions: [],
  currentQuestionType: '',
  answers: [],
  loading: false,
  saved: false,
  user: {},
  stopTimer: false,
  instruction: { tos1: false , tos2: false , welcome : false, reading: false, listening: false, writing: false, speaking: false , invoke : false },
  recorder: '',
  activeWebcam: '',
  activeMic: '',
  canvasStreamStore: '',
  ws: '',
  mR : '',
  stream : '',
  sStream : '',
  OS : '',
  writingAllWords : '',
  tabControl : 0
});
export const useSnap = () => useSnapshot(write);





export const quiz = {
  "English101": {
    "quizTitle": "React Quiz Component Demo",
    "quizSynopsis": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    "nrOfQuestions": "2",
    "allAnswers": [3, 2],
    "quizID": "English101",

    "questions": [
      {
        "question": "How can you access the state of a component from inside of a member function?",
        "questionType": "reading",
        "questionPic": "https://dummyimage.com/600x400/000/fff&text=X", // if you need to display Picture in Question
        "answerSelectionType": "single",
        "dataPath": './assets/1.mp3',
        "answers": [
          "this.getState()",
          "this.prototype.stateValue",
          "this.state",
          "this.values"
        ],
        "correctAnswer": "3",
        "point": "20"
      },
      {
        "question": "I'm very happy _____ in India. I really miss being there.",
        "questionType": "reading",
        "questionPic": "https://dummyimage.com/600x400/000/fff&text=X", // if you need to display Picture in Question
        "answerSelectionType": "single",
        "dataPath": '',
        "answers": [
          "to live",
          "to have lived",
          "to be lived",
          "to be living"
        ],
        "correctAnswer": "2",
        "point": "20"
      },

    ]
  }
}


const questions = {
  "1": {
    "question": "How can you access the state of a component from inside of a member function?",
    "questionType": "reading",
    "qID": "1",
    "questionPic": "https://dummyimage.com/600x400/000/fff&text=X",
    "answerSelectionType": "single",
    "dataPath": "./assets/1.mp3",
    "answers": [
      "this.getState()",
      "this.prototype.stateValue",
      "this.state",
      "this.values"
    ],
    "correctAnswer": "3",
    "point": "20"
  },
  "2": {
    "question": "I'm very happy _____ in India. I really miss being there.",
    "questionType": "reading",
    "qID": "2",
    "questionPic": "https://dummyimage.com/600x400/000/fff&text=X",
    "answerSelectionType": "single",
    "dataPath": "",
    "answers": [
      "to live",
      "to have lived",
      "to be lived",
      "to be living"
    ],
    "correctAnswer": "2",
    "point": "20"
  },
  "3": {
    "question": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
    "questionType": "reading",
    "qID": "3",
    "questionPic": "https://dummyimage.com/600x400/000/fff&text=X",
    "answerSelectionType": "single",
    "dataPath": "",
    "answers": [
      "sit amet",
      "consectetur",
      "adipiscing",
      "ttempor"
    ],
    "correctAnswer": "1",
    "point": "20"
  }
}


const dynamicID = {
  "email": "asd@asd.com",
  "name": "John Doe",
  "questions": [],
  "answers": [],
  "language": "E",
  "company": "company name",
  "welcomeMessage": "",
  "noq": { "reading": 1, "listening": 1, "writing": 0, "speaking": 1 },
  "logCount": 0,
  "score": {
    "reading": 1,
    "writing": 1,
    "speaking": 1,
    "listening": 1
  },
  "password" : "123",
}

