import '../App.css';
import React from 'react';
import { write, useSnap } from '../store'
import Lottie from "lottie-react";
import soundWave from '../assets/soundwave.json'
import playbutton from '../assets/playbutton.json'

let listeningTimer
function Listening({ dataPath }) {
  const read = useSnap()
  const [clicked, setClicked] = React.useState(false);
  const [hideAudio, setHideAudio] = React.useState(false);
  const audioRef = React.useRef()


  React.useEffect(() => {
    listeningTimer && clearTimeout(listeningTimer)
    write.stopTimer = true // the timer stops and resumes after the person is done hearing the questions.
    const forceTimeOut = setTimeout(() => {
      handleClick()
    }, 120000); // this should be 120000
    return () => {
      // 👇️ clear timeout when the component unmounts
      clearTimeout(forceTimeOut);
    };
  }, []);


  console.log('😊😊😊' , read.stopTimer)


  function handleClick() {
    setClicked(true)
    console.log(~~audioRef.current.duration * 1000 , audioRef.current , `${process.env.REACT_APP_DOMAIN}${dataPath}`)
    audioRef.current.play()
    listeningTimer = setTimeout(() => {
      write.stopTimer = false
      setHideAudio(true)
    }, ~~audioRef.current.duration * 1000);
  }

  return (
    <>
      {
        clicked ? <Lottie className={`${hideAudio && 'hidden' } w-24 self-center`} animationData={soundWave} loop={true} /> : <button className='self-center' ><Lottie className=' w-24' animationData={playbutton} loop={true} onClick={handleClick} /></button>
      }
      <audio className=' hidden' ref={audioRef} controls id="testTone" preload="metadata">
        <source src={`${process.env.REACT_APP_DOMAIN}${dataPath}`} type="audio/mp3" />
      </audio>
    </>
  );
}

export default Listening;