import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function AraSpeaking() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    يتضمن الجزء الناطق <span className='Text-Red-600'> {read.user.noq.speaming} </span> أسئلة ولديك 3 دقائق للإجابة على كل منها.
                </p>
                <br />
                <p>
                    سترى الموقت للأسئلة ذات الصلة في أعلى يمين التطبيق ويتم تسجيل الكاميرا في أعلى اليسار.سيستغرق الجزء المتحدث <span className='text-Red-600'> إجمالي {read.user.noq.speaming * 3} للإجابة على جميع الأسئلة. </span> يرجى أخذ وقتك.
                </p>
                <br />
                <p>
                    يجب أن تتحدث على الأقل <span className='text-Red-600'> 1 </span> دقيقة لتكون ضمن النطاق المقبول لتقييمنا.
                </p>
                <br />
                <p>
                    <span className='Text-Red-600'> لا يمكنك القراءة من نص مكتوب أو خطاب محفوظ </span>.نحتاج إلى سماع صوتك وصوتك بمفرده دون مساعدة أي شخص من حولك.
                </p>
                <br />
                <p>
                    عندما تكون جاهزًا ، يجب عليك الضغط على زر التسجيل 🔴 وعند الانتهاء ، يجب عليك إيقاف التسجيل.إذا لم تضغط على زر STOP ، فسيقوم التطبيق تلقائيًا بإنهاء التسجيل بعد وقت معين.
                </p>
                <br />
                <p>
                    يتم تسجيل شاشتك وحركتك وعينك.سوف تتلقى 0 إذا فتحت طلبًا من طرف ثالث أو غش في أي نوع من النماذج.
                </p>
                <br />
                <h1 className='text-2xl'>
                    إذا فهمت التعليمات ، فيمكنك الضغط على فهم الجزء الحديث وبدء تشغيله.
                </h1>

            </div>

        </>
    )



}


export default AraSpeaking